import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/redux';
import { AuthState } from '.';

export const selectAuth = (state: RootState): AuthState => state.auth;

export const authSelectorState = createSelector(selectAuth, (state) => state);
export const userIdSelector = createSelector(
  selectAuth,
  (state) => state.data.id
);

export const userLoggedStatus = createSelector(
  selectAuth,
  (state) => state.data.roles
);

export const userCurrentLanguage = createSelector(
  selectAuth,
  (state) => state.data.language
);
