import {
  RegCountries,
  RegCountriesRu,
  RegUkrRegions,
  RegUkrRegionsRU,
} from '@/assets/data/reg-location';
import { CommonPageWrapper } from '@/common/common-page-wrapper';
import { CommonTileContainer } from '@/common/common-tile-container';
import { getCurrentLanguage } from '@/i18n';
import {
  EditProfileSaveButton,
  UserContainer,
  UserDataOption,
  UserDataOptions,
  UserDataOptionsWrapper,
  UserDataSelectorOption,
  UserInfo,
  UserInfoPhoto,
} from '@pages/AuthorizedPage/EditProfile/styled';
import {
  EditProfileSection,
  FlexColumn,
  ProfileEditPasswordDesktopLink,
  ProfilePageHeader,
  ProfilePageItemHeader,
  UserPageCaption,
  UserPageTitle,
} from '@pages/main/styled';
import { useEditProfileMutation, useGetProfileQuery } from '@redux/auth';
import { VisitorPageFormError } from '@styles/Wrappers/Visitor/VisitorPageFormError';
import {
  EMAIL,
  MAXIMUM_VALUE,
  MINIMUM_VALUE,
  REQUIRED,
} from '@validation/messages';
import {
  Field,
  FieldInputProps,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikState,
} from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import ProfilePic from '../../../assets/img/user-photo.png';
import PhoneInput from 'react-phone-input-2';

interface MyFormikValues {
  name: string | undefined;
  town: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  surname: string | undefined;
  country: string | undefined;
}

const EditProfileSchema = Yup.object().shape({
  name: Yup.string(),
  town: Yup.string(),
  phone: Yup.string()
    .required(REQUIRED())
    .min(12, MINIMUM_VALUE())
    .max(13, MAXIMUM_VALUE()),
  surname: Yup.string(),
  country: Yup.string(),
  email: Yup.string().trim().email(EMAIL()).required(REQUIRED()),
});

export default function EditProfile(): JSX.Element {
  const { t } = useTranslation();
  const { data: user } = useGetProfileQuery();
  const [editProfile] = useEditProfileMutation();
  const currentLang = getCurrentLanguage();
  const countryLocale = currentLang === 'ua' ? RegCountries : RegCountriesRu;
  const townLocale = currentLang === 'ua' ? RegUkrRegions : RegUkrRegionsRU;

  const initialValues: MyFormikValues = {
    name: '' || user?.name,
    town: '' || user?.town,
    email: '' || user?.email,
    phone: '' || user?.phone,
    surname: '' || user?.surname,
    country: '' || user?.country,
  };

  const formSubmit = async function (value: MyFormikValues) {
    const editPromise = editProfile(value).unwrap();
    toast.promise(editPromise, {
      pending: t('Edit-profile.request-pending'),
      success: t('Edit-profile.request-success'),
      error: t('Edit-profile.request-error'),
    });
  };

  const editUserSelectorStyles = {
    option: (provided: Record<string, string>) => ({
      ...provided,
      background: '#FFF',
      fontSize: 14,
      fontWeight: 400,
      color: '#000',
      '&:hover': {
        background: '#E1E5EB',
      },
    }),
    valueContainer: (provided: Record<string, string>) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      height: 40,
    }),
    singleValue: (provided: Record<string, string>) => ({
      ...provided,
      position: 'static',
      transform: 'none',
      fontSize: 14,
    }),
    menu: (provided: Record<string, string>) => ({
      ...provided,
      marginTop: 5,
    }),
    menuList: (provided: Record<string, string>) => ({
      ...provided,
      maxHeight: 120,
    }),
  };

  const formatOptionLabel = useCallback(
    ({ value }) => t(`Location.${value}`),
    []
  );
  const formatOptionTownLabel = useCallback(
    ({ value }) => t(`UkrRegions.${value}`),
    []
  );

  return (
    <CommonPageWrapper>
      <ProfilePageHeader>
        <EditProfileSection>
          <FlexColumn>
            <UserPageTitle>{t('Edit-profile.title')}</UserPageTitle>
            <UserPageCaption>{t('Edit-profile.caption')}</UserPageCaption>
          </FlexColumn>
        </EditProfileSection>
        <ProfileEditPasswordDesktopLink
          to={{
            pathname: '/forgot-password',
            state: {
              isChangePassword: true,
            },
          }}
        >
          {t('Profile.change-pass')}
        </ProfileEditPasswordDesktopLink>
      </ProfilePageHeader>
      <CommonTileContainer>
        <ProfilePageItemHeader>
          <h3>{t('Edit-profile.overall')}</h3>
        </ProfilePageItemHeader>
        <UserContainer>
          <UserInfo>
            <UserInfoPhoto src={ProfilePic} />
          </UserInfo>
          <UserDataOptionsWrapper>
            <Formik
              initialValues={initialValues}
              validationSchema={EditProfileSchema}
              onSubmit={formSubmit}
            >
              {({ errors, touched }: FormikState<MyFormikValues>) => (
                <Form>
                  <UserDataOptions>
                    <UserDataOption>
                      <label htmlFor="userName">{t('Edit-profile.name')}</label>
                      <Field type="text" name="name" id="userName" />
                      {errors.name && touched.name ? (
                        <VisitorPageFormError>
                          {t(errors.name)}
                        </VisitorPageFormError>
                      ) : null}
                    </UserDataOption>
                    <UserDataOption>
                      <label htmlFor="userSurname">
                        {t('Edit-profile.surname')}
                      </label>
                      <Field type="text" name="surname" id="userSurname" />
                      {errors.name && touched.name ? (
                        <VisitorPageFormError>
                          {t(errors.name)}
                        </VisitorPageFormError>
                      ) : null}
                    </UserDataOption>
                    <UserDataOption>
                      <label htmlFor="userEmail">Email</label>
                      <Field type="text" name="email" id="userEmail" />
                      {errors.name && touched.name ? (
                        <VisitorPageFormError>
                          {t(errors.name)}
                        </VisitorPageFormError>
                      ) : null}
                    </UserDataOption>
                    <UserDataOption>
                      <label htmlFor="userPhoneNumber">Телефон</label>
                      {/* <Field type="text" name="phone" id="userPhoneNumber" /> */}
                      <Field id={`phone`} name={`phone`} type="text">
                        {({
                          field,
                          form,
                        }: FieldProps<MyFormikValues['phone']>) => (
                          <>
                            <PhoneInput
                              {...(field as unknown)}
                              country={'ua'}
                              disableSearchIcon={true}
                              onlyCountries={['ua', 'ru']}
                              value={field.value}
                              onChange={(phone) => {
                                form.setFieldValue(field.name, `+${phone}`);
                              }}
                            />
                          </>
                        )}
                      </Field>
                      {errors.name && touched.name ? (
                        <VisitorPageFormError>
                          {t(errors.name)}
                        </VisitorPageFormError>
                      ) : null}
                    </UserDataOption>
                    <UserDataSelectorOption>
                      <label htmlFor="userCountry">
                        {t('Edit-profile.country')}
                      </label>
                      <Field>
                        {({
                          field,
                          form,
                        }: {
                          field: FieldInputProps<{ country: string }>;
                          form: FormikHelpers<{ country: string }>;
                        }) => (
                          <Select
                            isDisabled
                            name="country"
                            id="userCountry"
                            options={countryLocale as unknown as undefined}
                            defaultValue={{
                              value: field.value.country,
                              label: field.value,
                            }}
                            onChange={(e) =>
                              form.setFieldValue('country', e?.value)
                            }
                            styles={
                              editUserSelectorStyles as unknown as undefined
                            }
                            formatOptionLabel={formatOptionLabel}
                          />
                        )}
                      </Field>
                    </UserDataSelectorOption>
                    <UserDataSelectorOption>
                      <label htmlFor="userTown">{t('Edit-profile.town')}</label>
                      <Field>
                        {({
                          field,
                          form,
                        }: {
                          field: FieldInputProps<{ town: string }>;
                          form: FormikHelpers<{ town: string }>;
                        }) => (
                          <Select
                            name="town"
                            id="userTown"
                            options={townLocale as unknown as undefined}
                            defaultValue={{
                              value: field.value.town,
                              label: field.value,
                            }}
                            onChange={(e) =>
                              form.setFieldValue('town', e?.value)
                            }
                            styles={
                              editUserSelectorStyles as unknown as undefined
                            }
                            formatOptionLabel={formatOptionTownLabel}
                          />
                        )}
                      </Field>
                    </UserDataSelectorOption>
                  </UserDataOptions>
                  <EditProfileSaveButton type="submit">
                    {t('Edit-profile.save-button')}
                  </EditProfileSaveButton>
                </Form>
              )}
            </Formik>
          </UserDataOptionsWrapper>
        </UserContainer>
      </CommonTileContainer>
    </CommonPageWrapper>
  );
}
