import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

interface CssState {
  sidebarOpen: boolean;
}

export enum EColorScheme {
  DAY = 'light',
  NIGHT = 'dark',
}

const initialState: CssState = {
  sidebarOpen: false,
};

export const CssSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    changeSidebarState: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { changeSidebarState } = CssSlice.actions;

export default CssSlice.reducer;
