import { WebinarWithTransformedLector } from '@/components/WebinarModal/FormikHTML';
import { IWebinar } from '@/interfaces/IWebinar';
import { returnHeaders } from '@/utils/functions';
import { projectApi } from '@redux/api';
import { Object2FormData } from '../api/convertObjectToFormdata';

const ApiType = 'Webinars';
const ApiID = 'LIST';

export type WebinarResponse = IWebinar[];

export interface IWebinarPopular extends IWebinar {
  popular: IWebinar[];
}

export type OneWebinarResponse = IWebinarPopular;
export type WebinarWithoutImg = Omit<IWebinar, 'img'>;
type CreateWebinarDTO = WebinarWithTransformedLector;
// interface CreateWebinarDTO
//   extends Omit<IWebinar, 'img' | 'lectors_ID' | '_id'> {
//   lectors_ID: string[];
// }

const extendedApi = projectApi
  .enhanceEndpoints({
    addTagTypes: [ApiType],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getWebinars: build.query<WebinarResponse, void>({
        query: () => {
          const myHeaders = returnHeaders('vebinars');
          return {
            url: 'vebinars',
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          };
        },
        providesTags: (result) => {
          if (result) {
            const arrayOfTags = result.map(({ _id: id }) => ({
              type: ApiType as typeof ApiType,
              id: id,
            }));
            return [...arrayOfTags, { type: ApiType, id: ApiID }];
          } else return [{ type: ApiType, id: ApiID }];
        },
      }),
      addWebinar: build.mutation<IWebinar, CreateWebinarDTO>({
        query(body) {
          console.log(body);
          const myHeaders = returnHeaders('vebinars');
          const objectInFormData = Object2FormData(body);
          return {
            url: `vebinars`,
            headers: myHeaders,
            method: 'POST',
            redirect: 'follow',
            body: objectInFormData,
          };
        },
        // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
        // that newly created post could show up in any lists.
        invalidatesTags: [{ type: ApiType, id: ApiID }],
      }),
      getWebinar: build.query<OneWebinarResponse, string>({
        query: (id) => {
          const myHeaders = returnHeaders(`vebinars/${id}`);
          return {
            url: `vebinars/${id}`,
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
          };
        },
        providesTags: (result, error, id) => {
          return [{ type: ApiType, id }];
        },
      }),
      updateWebinar: build.mutation<
        IWebinar,
        Partial<WebinarWithTransformedLector>
      >({
        query(data) {
          const { _id, ...body } = data;
          const myHeaders = returnHeaders(`vebinars/${_id}`);
          const objectInFormData = Object2FormData(body);
          return {
            url: `vebinars/${_id}`,
            method: 'PUT',
            redirect: 'follow',
            headers: myHeaders,
            body: objectInFormData,
          };
        },
        async onQueryStarted({ _id }, { dispatch, queryFulfilled }) {
          try {
            if (_id) {
              const { data: updatedPost } = await queryFulfilled;
              dispatch(
                extendedApi.util.updateQueryData('getWebinar', _id, (draft) => {
                  Object.assign(draft, updatedPost);
                })
              );
              dispatch(
                extendedApi.util.updateQueryData(
                  'getWebinars',
                  undefined,
                  (draft) => {
                    const oneWebinar = draft.find(
                      (webinar) => webinar._id === _id
                    );
                    Object.assign(oneWebinar, updatedPost);
                  }
                )
              );
            }
          } catch {
            throw new Error('Ошибка с запросом');
          }
        },
      }),
      updateWebinarWatchAmount: build.mutation<IWebinar, string>({
        query(data) {
          const myHeaders = returnHeaders(`vebinars/views/${data}`);
          return {
            url: `vebinars/views/${data}`,
            method: 'PUT',
            redirect: 'follow',
            headers: myHeaders,
          };
        },

        invalidatesTags: (result, error, id) => [{ type: ApiType, id }],
      }),
      deleteWebinar: build.mutation<{ success: boolean; id: number }, string>({
        query(id) {
          const myHeaders = returnHeaders(`vebinars/${id}`);
          return {
            url: `vebinars/${id}`,
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
          };
        },
        invalidatesTags: (result, error, id) => [{ type: ApiType, id }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetWebinarsQuery,
  useAddWebinarMutation,
  useGetWebinarQuery,
  useUpdateWebinarMutation,
  useDeleteWebinarMutation,
  useUpdateWebinarWatchAmountMutation,
} = extendedApi;
