import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  IProfilePageToggleButton,
  IUserVideosLayout,
  IPurchasedItem,
  ISolidBlock,
} from './types';

export const UserProfile = styled.div`
  width: 100%;
  padding: 23px;
  gap: 20px;
  height: 267px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media screen and (max-width: 599px) {
    height: 100%;
    justify-content: center;
  }
`;

export const UserPhoto = styled.img`
  width: 22.5%;
  max-width: 150px;
  /* margin: 0 50px; */
  @media screen and (max-width: 1366px) {
    width: 120px;
    margin-right: 31px;
  }
  @media screen and (max-width: 599px) {
    margin: 0 0 20px 0;
  }
`;
export const ProfilePageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
  @media screen and (max-width: 599px) {
    display: block;
  }
`;

export const UserPageCaption = styled.p`
  font-size: 10px;
  font-weight: 400;
  color: #3d5170;
  text-transform: uppercase;
  letter-spacing: 2.5px;
`;

export const UserPageTitle = styled.h3`
  font-size: 26px;
  font-weight: 400;
  color: #3d5170;
`;

export const EditProfileButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border: none;
  border-radius: 50%;
  background: linear-gradient(180deg, #80bb37 0%, #367622 100%);
  @media screen and (max-width: 599px) {
    width: 45px;
    height: 45px;
    margin-left: 0;
  }
`;

export const ProfilePageData = styled.div<ISolidBlock>`
  display: grid;
  grid-template-rows: 267px auto;
  grid-template-columns: ${(props) => (props.isSolidBlock ? '' : '37% 61.5%')};
  grid-template-areas: ${(props) =>
    props.isSolidBlock
      ? 'personal-data'
      : "'personal-data user-videos' 'purchase-history user-videos'"};
  grid-gap: 24px 1.5%;
  margin-top: 24px;
  @media screen and (max-width: 1366px) {
    grid-template-rows: auto;
    /* grid-template-rows: 200px auto auto; */
    grid-template-columns: 1fr;
    grid-template-areas: 'personal-data' 'user-videos' 'purchase-history';
    grid-gap: 24px 0;
  }
  @media screen and (max-width: 599px) {
    grid-template-rows: auto auto auto;
    grid-gap: 20px;
    margin-top: 0;
  }
`;

export const PersonalTextData = styled.div`
  display: flex;
  @media screen and (max-width: 599px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const UserNameAndHome = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  @media screen and (max-width: 599px) {
    align-items: center;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const UserFullname = styled.p`
  font-size: 24px;
  font-weight: 400;
  color: #3d5170;
`;

export const UserHometown = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #ababab;
`;

export const UserAdminLabel = styled.p`
  width: 50px;
  height: 22.5px;
  margin-top: 5px;
  padding: 4px 8px;
  background: linear-gradient(180deg, #80bb37 0%, #367622 100%);
  border-radius: 15px;
  font-size: 12px;
  color: #fff;
  @media screen and (max-width: 599px) {
    margin: 10px 0;
  }
`;
export const ProfileEditPasswordButton = styled.button`
  max-height: 40px;
  cursor: pointer;
  min-width: 90px;
  padding: 12px 15px;
  background: linear-gradient(180deg, #80bb37 0%, #428e2a 100%);
  border: none;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  @media screen and (max-width: 599px) {
    display: none;
  }
`;

export const ProfileEditPasswordDesktopLink = styled(Link)`
  max-height: 40px;
  min-width: 90px;
  padding: 12px 15px;
  background: linear-gradient(180deg, #80bb37 0%, #428e2a 100%);
  border: none;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  @media screen and (max-width: 599px) {
    display: none;
  }
`;

export const ProfileEditPasswordMobileLink = styled(
  ProfileEditPasswordDesktopLink
)`
  cursor: pointer;
  display: none;
  @media screen and (max-width: 599px) {
    display: block;
    width: 100%;
  }
`;

export const EditProfileSection = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 599px) {
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0;
`;

export const ProfilePageItemHeader = styled.div`
  height: 50px;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid #dee2e6;
  @media screen and (max-width: 599px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #dee2e6;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
    height: auto;
    padding: 12px 24px;
  }
  h3 {
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    font-weight: 400;
    color: #3d5170;
  }
`;

export const ProfilePageToggleButton = styled.button<IProfilePageToggleButton>`
  display: none;
  @media screen and (max-width: 599px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border: none;
    border-radius: 50px;
    background: linear-gradient(180deg, #80bb37 0%, #428e2a 100%);
    svg {
      transform: ${(props) => (props.isDropdown ? 'none' : 'rotate(180deg)')};
    }
  } ;
`;

export const PurchasedItem = styled.div<IPurchasedItem>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #dee2e6;
  &:last-child {
    border-bottom: none;
  }
  @media screen and (max-width: 599px) {
    display: ${(props) => props.purchaseItemsDisplay || 'flex'};
  }
`;

export const PurchasedItemInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 78%;
  svg {
    width: 40px;
    height: 24px;
  }
`;

export const PurchasedItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 19px - 40px);
  margin-left: 19px;
  font-size: 14px;
`;

export const PurchasedItemName = styled.p`
  margin-bottom: 6px;
  color: #5a6169;
`;

export const PurchasedItemPurchaseDate = styled.p`
  color: #c4c4c4;
`;

export const PurchasedItemPrice = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #5a6169;
  white-space: nowrap;
`;

export const UserVideosLayout = styled.div<IUserVideosLayout>`
  display: grid;
  width: 100%;
  grid-area: user-videos;
  grid-template-columns: 1fr 1fr;
  grid-gap: 43px 23px;
  padding: 23px;

  &[data-amount='1'] {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 599px) {
    display: ${(props) => props.userVideosDisplay || `grid`};
    grid-template-columns: 1fr;
    grid-gap: 0 0;
    padding: 0;
  }
`;

export const UserVideo = styled.div`
  grid-area: user-videos;
`;
