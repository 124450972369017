export enum LinksEnum {
  MAIN = '/',
  LOGIN = '/login',
  EDIT = '/edit',
  WEBINARS = '/webinars',
  REGISTRATION = '/registration',
  USER_AGREEMENTS = '/user-agreements',
  PAYMENT = '/payment-and-conditions',
  FORGOT_PASSWORD = '/forgot-password',
  NEW_PASSWORD = '/new-password',
  LECTORS = '/lectors',
  NOTFOUND = '*',
  USERS = '/users',
  ORDERS = '/orders',
  NOTIFICATIONS = '/notifications',
}
