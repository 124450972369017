import { ILector } from '@/interfaces/ILector';
import { returnHeaders } from '@/utils/functions';
import { projectApi } from '@redux/api';
import { Object2FormData } from '../api/convertObjectToFormdata';
const ApiType = 'Lectors';
const ApiID = 'LIST';

export type LectorResponse = ILector[];
// export type LectorWithoutAvatar = Omit<ILector, 'avatar'>;

export interface LectorWithFileAvatar extends Omit<ILector, 'avatar'> {
  avatar: File | string;
}

export type CreateLectorDTO = Omit<ILector, 'avatar' | '_id'>;
export type UpdateLectorDTO = Partial<LectorWithFileAvatar>;

const extendedApi = projectApi
  .enhanceEndpoints({
    addTagTypes: [ApiType],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getLectors: build.query<LectorResponse, void>({
        query: () => {
          const myHeaders = returnHeaders('lectors');
          return {
            url: 'lectors',
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          };
        },
        providesTags: (result) => {
          if (result) {
            const arrayOfTags = result.map(({ _id: id }) => ({
              type: ApiType as typeof ApiType,
              id: id,
            }));
            return [...arrayOfTags, { type: ApiType, id: ApiID }];
          } else return [{ type: ApiType, id: ApiID }];
        },
      }),
      addLector: build.mutation<ILector, CreateLectorDTO>({
        query(body) {
          const myHeaders = returnHeaders('lectors');
          const objectInFormData = Object2FormData(body);
          return {
            url: `lectors`,
            headers: myHeaders,
            method: 'POST',
            redirect: 'follow',
            body: objectInFormData,
          };
        },
        // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
        // that newly created post could show up in any lists.
        invalidatesTags: [{ type: ApiType, id: ApiID }],
      }),
      getLector: build.query<ILector, string>({
        query: (id) => {
          const myHeaders = returnHeaders(`lectors/${id}`);
          return {
            url: `lectors/${id}`,
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
          };
        },
        providesTags: (result, error, id) => [{ type: ApiType, id }],
      }),
      updateLector: build.mutation<ILector, Partial<LectorWithFileAvatar>>({
        query(data) {
          const { _id, ...body } = data;
          const myHeaders = returnHeaders(`lectors/${_id}`);
          const objectInFormData = Object2FormData(body);
          return {
            url: `lectors/${_id}`,
            method: 'PUT',
            redirect: 'follow',
            headers: myHeaders,
            body: objectInFormData,
          };
        },

        invalidatesTags: (result, error, { _id: id }) => [
          { type: ApiType, id },
        ],
      }),

      deleteLector: build.mutation<{ success: boolean; id: number }, string>({
        query(id) {
          const myHeaders = returnHeaders(`lectors/${id}`);
          return {
            url: `lectors/${id}`,
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
          };
        },
        invalidatesTags: (result, error, id) => [{ type: ApiType, id }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetLectorsQuery,
  useAddLectorMutation,
  useGetLectorQuery,
  useUpdateLectorMutation,
  useDeleteLectorMutation,
} = extendedApi;
