import React from 'react';
import { UserTexts } from '@pages/AuthorizedPage/UserAgreements/styled';

const UserAgreementsTextUA = (): JSX.Element => {
  return (
    <UserTexts>
      <h2>Угода користувача</h2>
      <br />
      <p>
        Дана Політика конфіденційності персональних даних (далі – Політика
        конфіденційності) діє відносно всієї інформації, яку Громадська
        організація «Гільдія ландшафтних архітекторів України» (ГО ГЛАУ),
        розташована на доменному імені glau.com.ua, може отримати про
        Користувача під час використання сайту webinars.glau.com.ua, продуктів
        ГЛАУ.
      </p>
      <br />
      <p>1. ЗАГАЛЬНІ ПОЛОЖЕННЯ</p>
      <br />
      <p>
        1.1. Використання Користувачем сайту означає згоду з цією Політикою
        конфіденційності та умовами обробки персональних даних Користувача.
      </p>
      <p>
        1.2. У разі незгоди з умовами Політики конфіденційності Користувач
        повинен припинити використання сайту.
      </p>
      <p>
        1.3. Дана Політика конфіденційності застосовується лише до сайту
        webinars.glau.com.ua.
      </p>
      <p>
        1.4. Адміністрація сайту не перевіряє достовірність персональних даних,
        що надаються Користувачем сайту.
      </p>
      <br />
      <p>2. ПРЕДМЕТ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ</p>
      <br />
      <p>
        2.1. Дана Політика конфіденційності встановлює зобов&apos;язання
        Адміністрації сайту щодо нерозголошення та забезпечення режиму захисту
        конфіденційності персональних даних, які Користувач повинен за вимогою
        надати Адміністрації сайту при реєстрації на сайті або при оформленні
        замовлення для придбання Товару.
      </p>
      <p>
        2.2. Персональні дані, дозволені до обробки в рамках цієї Політики
        конфіденційності, надаються Користувачем шляхом заповнення реєстраційної
        форми на Сайті в розділі Особистий Кабінет і включають в себе наступну
        інформацію:
      </p>
      <p>2.2.1. прізвище, ім&apos;я, по батькові Користувача;</p>
      <p>2.2.2. контактний телефон Користувача;</p>
      <p>2.2.3. адреса електронної пошти (e-mail);</p>
      <p>2.2.4. місто проживання Користувача.</p>
      <p>
        2.3. Інтернет-магазин захищає Дані, які автоматично передаються в
        процесі перегляду рекламних блоків і при відвідуванні сторінок, на яких
        встановлено статистичний скрипт системи (&apos;піксель&apos;):
      </p>
      <p>• IP адреса;</p>
      <p>• інформація з cookies;</p>
      <p>
        • інформація про браузер (чи іншу програму, яка здійснює доступ до
        показу реклами);
      </p>
      <p>• час доступу;</p>
      <p>• адреса сторінки, на якій розташований рекламний блок;</p>
      <p>• реферер (адреса попередньої сторінки).</p>
      <p>
        2.3.1. Відключення cookies може спричинити неможливість доступу до
        частин сайту Інтернет-магазину, що вимагають авторизації.
      </p>
      <p>
        2.3.2. Інтернет-магазин здійснює збір статистики про IP-адреси своїх
        відвідувачів. Дана інформація використовується з метою виявлення і
        вирішення технічних проблем, для контролю законності проведених
        фінансових платежів.
      </p>
      <p>
        2.4. Будь-яка інша персональна інформація не обговорена вище (історія
        покупок, використовувані браузери і операційні системи і т.д.) підлягає
        надійному зберіганню і нерозповсюдженню, за винятком випадків,
        передбачених в п.п. 5.2. і 5.3. цієї Політики конфіденційності.
      </p>
      <br />
      <p>3. ЦІЛІ ЗБОРУ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ КОРИСТУВАЧА</p>
      <br />
      <p>
        3.1. Персональні дані Користувача Адміністрація сайту може
        використовувати в цілях:
      </p>
      <p>
        3.1.1. Ідентифікації Користувача, зареєстрованого на сайті, для
        оформлення замовлення.
      </p>
      <p>
        3.1.2. Надання Користувачеві доступу до персоналізованих ресурсів Сайту.
      </p>
      <p>
        3.1.3. Встановлення з Користувачем зворотного зв&apos;язку, включаючи
        напрямок повідомлень, запитів, що стосуються використання Сайту
        інтернет-магазину, надання послуг, обробка запитів і заявок від
        Користувача.
      </p>
      <p>
        3.1.4. Визначення місця знаходження Користувача для забезпечення
        безпеки, запобігання шахрайства.
      </p>
      <p>
        3.1.5. Підтвердження достовірності та повноти персональних даних,
        наданих Користувачем.
      </p>
      <p>
        3.1.6. Створення облікового запису для здійснення покупок, якщо
        Користувач дав згоду на створення облікового запису.
      </p>
      <p>
        3.1.7. Повідомлення Користувача Сайту інтернет-магазину про стан
        Замовлення.
      </p>
      <p>
        3.1.8. Обробки і отримання платежів, підтвердження податку або
        податкових пільг, заперечування платежу, визначення права на отримання
        кредитної лінії Користувачем.
      </p>
      <p>
        3.1.9. Надання Користувачеві ефективної клієнтської і технічної
        підтримки при виникненні проблем пов&apos;язаних з використанням Сайту
        інтернет-магазину.
      </p>
      <p>
        3.1.10. Надання Користувачеві з його згоди, оновлень продукції,
        спеціальних пропозицій, інформації про ціни, розсилки новин та інших
        відомостей від імені Інтернет-магазину або від імені партнерів
        Інтернет-магазину.
      </p>
      <br />
      <p>4. СПОСОБИ І ТЕРМІНИ ОБРОБКИ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ</p>
      <br />
      <p>
        4.1. Обробка персональних даних Користувача здійснюється без обмеження
        терміну, будь-яким законним способом, в тому числі в інформаційних
        системах персональних даних з використанням засобів автоматизації або
        без використання таких засобів.
      </p>
      <p>
        4.2. Персональні дані Користувача можуть бути передані уповноваженим
        органам державної влади України та тільки на підставах та в порядку,
        встановленим законодавством України.
      </p>
      <p>
        4.3. При втраті або розголошення персональних даних Адміністрація сайту
        інформує Користувача про втрату або розголошення персональних даних.
      </p>
      <p>
        4.4. Адміністрація сайту вживає необхідних організаційних і технічних
        заходів для захисту персональної інформації Користувача від
        неправомірного або випадкового доступу, знищення, перекручення,
        блокування, копіювання, поширення, а також від інших неправомірних дій
        третіх осіб.
      </p>
      <p>
        4.5. Адміністрація сайту спільно з Користувачем вживає всіх необхідних
        заходів щодо запобігання збитків або інших негативних наслідків,
        викликаних втратою або розголошенням персональних даних Користувача.
      </p>
      <br />
      <p>5. ЗОБОВ&apos;ЯЗАННЯ СТОРІН</p>
      <br />
      <p>5.1. Користувач зобов&apos;язаний:</p>
      <p>
        5.1.1. Надати інформацію про персональні дані, необхідну для
        користування Сайтом інтернет-магазину.
      </p>
      <p>
        5.1.2. Оновити, доповнити надану інформацію про персональні дані в разі
        зміни даної інформації.
      </p>
      <p>5.2. Адміністрація сайту зобов&apos;язана:</p>
      <p>
        5.2.1. Використовувати отриману інформацію виключно для цілей,
        зазначених у п. 3 цієї Політики конфіденційності.
      </p>
      <p>
        5.2.2. Забезпечити зберігання конфіденційної інформації в таємниці, не
        розголошувати без попередньої письмової згоди Користувача, а також не
        здійснювати продаж, обмін, опублікування, або розголошення іншими
        можливими способами переданих персональних даних Користувача, за
        винятком п. 4.2. цієї Політики Конфіденційності.
      </p>
      <p>
        5.2.3. Вживати запобіжні заходи для захисту конфіденційності
        персональних даних Користувача згідно з порядком, який зазвичай
        використовується для захисту такого роду інформації в існуючому діловому
        обороті.
      </p>
      <p>
        5.2.4. Здійснити блокування персональних даних, що відносяться до
        відповідного Користувача, з моменту звернення або запиту Користувача або
        його законного представника або уповноваженого органу з захисту прав
        суб&apos;єктів персональних даних на період перевірки, в разі виявлення
        недостовірних персональних даних або неправомірних дій.
      </p>
      <br />
      <p>6. ВІДПОВІДАЛЬНІСТЬ СТОРІН</p>
      <br />
      <p>
        6.1. Адміністрація сайту, яка не виконала свої зобов&apos;язання, несе
        відповідальність за збитки, понесені Користувачем у зв&apos;язку з
        неправомірним використанням персональних даних, відповідно до
        законодавства України, за винятком випадків, передбачених п.п. 4.2. і
        6.2. цієї Політики Конфіденційності.
      </p>
      <p>
        6.2. У разі втрати або розголошення Конфіденційної інформації
        Адміністрація сайту не несе відповідальність, якщо дана конфіденційна
        інформація:
      </p>
      <p>6.2.1. Стала публічним надбанням до її втрати або розголошення.</p>
      <p>
        6.2.2. Була отримана від третьої сторони до моменту її отримання
        Адміністрацією сайту.
      </p>
      <p>6.2.3. Була розголошена за згодою Користувача.</p>
      <br />
      <p>7. ВИРІШЕННЯ СУПЕРЕЧОК</p>
      <br />
      <p>
        7.1. До звернення в суд з позовом у спорах, що виникають із відносин між
        Користувачем сайту й Адміністрацією сайту, обов&apos;язковим є
        пред&apos;явлення претензії (письмової пропозиції про добровільне
        врегулювання спору).
      </p>
      <p>
        7.2 Одержувач претензії протягом 30 календарних днів з дня отримання
        претензії, письмово повідомляє заявника претензії про результати
        розгляду претензії.
      </p>
      <p>
        7.3. При не досягненні угоди суперечка буде передана на розгляд до
        судового органу відповідно до чинного законодавства України.
      </p>
      <p>
        7.4. До цієї Політики конфіденційності та відносин між Користувачем і
        Адміністрацією сайту застосовується чинне законодавство України.
      </p>
      <br />
      <p>8. ДОДАТКОВІ УМОВИ</p>
      <br />
      <p>
        8.1. Адміністрація сайту має право вносити зміни в дану Політику
        конфіденційності без згоди Користувача.
      </p>
      <p>
        8.2. Нова Політика конфіденційності вступає в силу з моменту її
        розміщення на Сайті, якщо інше не передбачено новою редакцією Політики
        конфіденційності.
      </p>
      <p>
        8.3. Всі пропозиції або питання по цій Політиці конфіденційності слід
        відправляти на пошту розташовану в розділі контакти.
      </p>
      <p>
        8.4. Діюча Політика конфіденційності розміщена на сторінці за адресою{' '}
        <a href="mailto:webinars.glau.com.ua">webinars.glau.com.ua</a>
      </p>
    </UserTexts>
  );
};

export default UserAgreementsTextUA;
