import { IOrder } from './IOrder';
import { INotification } from './INotification';
import { getCurrentLanguage } from '@/i18n';

export enum UserStatusType {
  VISITOR = 'visitor',
  AUTHED = 'auth',
  ADMIN = 'admin',
}

export interface IUser {
  notifications_ID: INotification[];
  orders_ID: IOrder[];
  roles: UserStatusType;
  id: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  country: string;
  town: string;
  language: ReturnType<typeof getCurrentLanguage>;
}
