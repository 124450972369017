import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from '@/redux';
import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router';
import * as Yup from 'yup';
import 'yup-jwt';
import { useHistory } from 'react-router-dom';
import { LinksEnum } from '@/interfaces/LinksEnum';

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

interface ICallbackEvent {
  timeout?: number;
}

interface IHandleCopyClick {
  beforeCopy?: () => void;
  afterCopy?: () => void;
  afterCopyTimeoutFired?: () => void;
  onError?: (error: ErrorEvent) => void;
}

export const useCopyText = function (
  mainProps: ICallbackEvent = {}
): [boolean, (copyText: string, functions?: IHandleCopyClick) => void] {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = (
    copyText: string,
    functions: IHandleCopyClick = {}
  ) => {
    // Asynchronously call copyTextToClipboard

    if (functions.beforeCopy) {
      functions.beforeCopy();
    }
    copyTextToClipboard(copyText ?? '')
      .then(() => {
        setIsCopied(true);
        if (functions.afterCopy) {
          functions.afterCopy();
        }
        setTimeout(() => {
          setIsCopied(false);
          if (functions.afterCopyTimeoutFired) {
            functions.afterCopyTimeoutFired();
          }
        }, mainProps?.timeout ?? 1500);
      })
      .catch(functions.onError ?? console.log);
  };
  return [isCopied, handleCopyClick];
};
export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions(): {
  width: number;
  height: number;
} {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

type CopyStatus = 'inactive' | 'copied' | 'failed';
export const useCopyToClipboard = (
  text: string,
  notifyTimeout = 2500
): [CopyStatus, () => void] => {
  const [copyStatus, setCopyStatus] = useState<CopyStatus>('inactive');
  const copy = useCallback(() => {
    navigator.clipboard.writeText(text).then(
      () => setCopyStatus('copied'),
      () => setCopyStatus('failed')
    );
  }, [text]);

  useEffect(() => {
    if (copyStatus === 'inactive') {
      return;
    }

    const timeoutId = setTimeout(
      () => setCopyStatus('inactive'),
      notifyTimeout
    );

    return () => clearTimeout(timeoutId);
  }, [copyStatus]);

  return [copyStatus, copy];
};

export const useToggle = (initialState = false): [boolean, () => void] => {
  // Initialize the state
  const [state, setState] = useState(initialState);

  // Define and memorize toggler function in case we pass down the comopnent,
  // This function change the boolean value to it's opposite value
  const toggle = useCallback(() => setState((state) => !state), []);

  return [state, toggle];
};

const tokenSchema = Yup.string().jwt('regex');

/**
 * Данный хук выполняет проверку переданного query-параметра страницы. При прохождении  проверки - возвращает токен. Иначе - редиректит на главную страницу
 * @return {string} JWT токен, по которому происходит восстановление почты.
 */
export function useJWTTokenQueryValidation(): string {
  const query = useQuery();
  const history = useHistory();

  const [token, setToken] = useState('');

  const tokenAsyncValidation = async function (token: string) {
    if (await tokenSchema.isValid(token)) {
      setToken(token);
    } else {
      history.push(LinksEnum.MAIN);
    }
  };

  useEffect(() => {
    const token = query.get('token') ?? '';
    tokenAsyncValidation(token);
  }, []);

  return token;
}
