import { INotification } from '@/interfaces/INotification';
import { returnHeaders } from '@/utils/functions';
import { projectApi } from '@redux/api';
const ApiType = 'Notififcation';
const ApiID = 'LIST';

export type NotificationsResponse = INotification[];
// export type LectorWithoutAvatar = Omit<INotification, 'avatar'>;

export interface LectorWithFileAvatar extends Omit<INotification, 'avatar'> {
  avatar: File | string;
}

export type CreateLectorDTO = Omit<INotification, 'avatar' | '_id'>;
export type UpdateLectorDTO = Partial<LectorWithFileAvatar>;

const extendedApi = projectApi
  .enhanceEndpoints({
    addTagTypes: [ApiType],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getNotificationsOfUser: build.query<NotificationsResponse, void>({
        query: () => {
          const myHeaders = returnHeaders('notification/list');
          return {
            url: 'notification/list',
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          };
        },
        providesTags: (result) => {
          if (result) {
            const arrayOfTags = result.map(({ _id: id }) => ({
              type: ApiType as typeof ApiType,
              id: id,
            }));
            return [...arrayOfTags, { type: ApiType, id: ApiID }];
          } else return [{ type: ApiType, id: ApiID }];
        },
      }),
      getNotification: build.query<INotification, string>({
        query: (id) => {
          const myHeaders = returnHeaders(`notification/${id}`);
          return {
            url: `notification/${id}`,
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
          };
        },
        providesTags: (result, error, id) => [{ type: ApiType, id }],
      }),

      deleteNotification: build.mutation<
        { success: boolean; id: number },
        string
      >({
        query(id) {
          const myHeaders = returnHeaders(`notification/${id}`);
          return {
            url: `notification/${id}`,
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
          };
        },
        invalidatesTags: (result, error, id) => [{ type: ApiType, id }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetNotificationQuery,
  useGetNotificationsOfUserQuery,
  useDeleteNotificationMutation,
} = extendedApi;
