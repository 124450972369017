import { CurrentLanguage } from '@/i18n';
import { IUser } from '@/interfaces/IUser';
import { returnHeaders } from '@/utils/functions';
import { UserRolesTypeFromResponse } from '@/utils/roles/strategy-checker';
import { projectApi } from '@redux/api';

export interface UserRoleFromResponse {
  value: UserRolesTypeFromResponse;
  _id: string;
}
export interface UserFromResponse extends Omit<IUser, 'roles' | 'id'> {
  _id: string;
  roles: UserRoleFromResponse[];
  preferredLanguage: CurrentLanguage;
}

export interface UserResponse {
  user: UserFromResponse;
  access_token: string;
}

export interface LoginRequest {
  email: string;
  password: string;
  saveMe: boolean;
}

export interface RecoveryPasswordByEmailRequest {
  email: string;
}
export interface RecoveryPasswordByEmailResponse {
  send: boolean;
  message: string;
}

export interface RecoveryPasswordNewPasswordRequest {
  token: string;
  newPassword: string;
}
export interface RecoveryPasswordByEmailResponse {
  success: boolean;
  user: UserFromResponse;
}
export interface RegisterUserRequest {
  name: string;
  surname: string;
  email: string;
  phone: string;
  country: string;
  town: string;
  password: string;
}

export interface EditUserRequest extends Partial<IUser> {
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  country: string | undefined;
  town: string | undefined;
}
const extendedApi = projectApi.injectEndpoints({
  endpoints: (builder) => ({
    createProfile: builder.mutation<string, RegisterUserRequest>({
      query: (data) => {
        const myHeaders = returnHeaders('/users');
        return {
          url: `/users`,
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: data,
        };
      },
    }),
    getProfile: builder.query<UserFromResponse, void>({
      query: () => {
        const myHeaders = returnHeaders('auth/profile');
        return {
          url: 'auth/profile',
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };
      },
    }),
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        return {
          url: 'auth/login',
          method: 'POST',
          body: credentials,
          headers: myHeaders,
          redirect: 'follow',
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;
          dispatch(
            projectApi.util.updateQueryData(
              'getProfile' as unknown as never,
              undefined as unknown as never,
              (draft) => {
                Object.assign(draft, updatedPost.user);
              }
            )
          );
        } catch {
          throw new Error('Error with login response');
        }
      },
    }),
    editProfile: builder.mutation<IUser, EditUserRequest>({
      query: (data) => {
        return {
          url: `/users`,
          method: 'PUT',
          redirect: 'follow',
          body: data,
        };
      },
    }),
    recoveryPassword: builder.mutation<
      RecoveryPasswordByEmailResponse,
      RecoveryPasswordByEmailRequest
    >({
      query: (credentials) => {
        const myHeaders = returnHeaders(
          `auth/recoverypass/${credentials.email}`
        );
        return {
          url: `auth/recoverypass/${credentials.email}`,
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
        };
      },
    }),
    newPasswordSend: builder.mutation<
      RecoveryPasswordByEmailResponse,
      RecoveryPasswordNewPasswordRequest
    >({
      query: ({ newPassword, token }) => {
        const myHeaders = returnHeaders(`auth/recoverypass/${token}`);
        return {
          url: `auth/recoverypass/${token}`,
          method: 'PUT',
          body: {
            newPassword,
          },
          headers: myHeaders,
          redirect: 'follow',
        };
      },
    }),
    logout: builder.mutation<UserResponse, string>({
      query: () => {
        return {
          url: 'auth/logout',
          method: 'POST',
          redirect: 'follow',
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            projectApi.util.updateQueryData(
              'getProfile' as unknown as never,
              undefined as unknown as never,
              (draft) => {
                Object.assign(draft, {});
              }
            )
          );
        } catch {
          throw new Error('Error with login response');
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateProfileMutation,
  useLoginMutation,
  useLogoutMutation,
  useGetProfileQuery,
  useEditProfileMutation,
  useNewPasswordSendMutation,
  useRecoveryPasswordMutation,
} = extendedApi;
