import { IUser, UserStatusType } from '@/interfaces/IUser';
import { authedUser } from '@mock/user';
import { createAsyncThunk } from '@reduxjs/toolkit';

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

type LoginReturned = IUser;

export const loginUserToWebSite = createAsyncThunk(
  'auth/loginUserToWebSite',
  async () => {
    await timeout(2000);
    return authedUser as LoginReturned;
  }
);

export const logoutUserFromWebSite = createAsyncThunk(
  'auth/logoutUserFromWebSite',
  async () => {
    await timeout(2000);
    return {
      roles: UserStatusType.VISITOR,
    } as LoginReturned;
  }
);
