import { persistor } from '@/redux';
import { useGetProfileQuery, useLogoutMutation } from '@redux/auth';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton } from './styled';
import { ReactComponent as ExitIcon } from '@icons/exit.svg';

type ILogoutButton = React.HtmlHTMLAttributes<HTMLButtonElement>;

export default function LogoutButton(props: ILogoutButton): JSX.Element {
  const { t } = useTranslation();
  const { data: user } = useGetProfileQuery();
  const [logout, { isSuccess }] = useLogoutMutation();
  const logoutClick = async function () {
    if (user && user._id) {
      await logout(user._id).unwrap();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      persistor.purge();
    }
  }, [isSuccess]);

  return (
    <StyledButton onClick={logoutClick} {...props}>
      <ExitIcon />
      <p>{t('User-content.sidebar.log-out')}</p>
    </StyledButton>
  );
}
