import { UserStatusType } from '@/interfaces/IUser';
import withAuthType from '@/layouts/Auth/AuthBasedLayout';
import { useAppDispatch } from '@/utils/hooks';
import {
  SidebarHeader,
  SidebarLink,
  SidebarWrapper,
} from '@components/Sidebar/styled';
import { IRoute } from '@interfaces/IRoutes';
import { changeSidebarState, selectSidebarState } from '@redux/css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import Logout from '@components/Logout';

interface IAsideProps {
  routes: IRoute[];
}

const Sidebar = ({ routes }: IAsideProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLinkMenu = useSelector(selectSidebarState);

  const sidebarChangeState = () => {
    dispatch(changeSidebarState());
  };

  return (
    <SidebarWrapper isLinkMenu={isLinkMenu}>
      <SidebarHeader>
        <Link to="/">
          <img src={Logo} alt="" />
        </Link>
      </SidebarHeader>
      <nav>
        {routes
          .filter((route) => route.link && route.sidebar)
          .map(({ link, name, path, exact, icon, ...props }, index) => {
            const Icon = icon;
            return (
              <SidebarLink
                key={index}
                tabIndex={0}
                activeClassName="active-sidebar-link"
                to={typeof link === 'string' ? link : path}
                exact={exact}
                onClick={sidebarChangeState}
                {...props}
              >
                {Icon && <Icon />}
                <p>{t(name)}</p>
              </SidebarLink>
            );
          })}

        <Logout />
      </nav>
    </SidebarWrapper>
  );
};

export default withAuthType([UserStatusType.AUTHED, UserStatusType.ADMIN])(
  Sidebar
);
