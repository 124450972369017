import styled from 'styled-components';
import React from 'react';
export const StyledWrapper = styled.section`
  width: 100%;
  position: relative;
  background: rgba(241, 243, 245, 1);
  padding-bottom: 25px;
  z-index: var(--main-zIndex-main);

  /* max-width: var(--main-max-width); */
  /* margin: 0 auto; */

  min-height: calc(
    100vh - var(--main-header-height) - var(--main-footer-height)
  );
  padding-left: var(--main-sidebar-padding);

  padding-bottom: calc(var(--main-footer-height) + 50px);
  @media screen and (max-width: 1024px) {
    padding-left: var(--main-sidebar-padding);
    padding-top: 0;
    padding-right: var(--main-sidebar-padding);
  }
`;
export const StyledOverlay = styled.section`
  --main-zIndex-header: 18;
  --main-zIndex-sidebar: 19;
  --main-zIndex-footer: 20;
  --main-zIndex-main: 17;

  --main-max-width: 1266px;

  --main-sidebar-padding: 320px;

  --main-header-height: 60px;
  --main-footer-height: 110px;

  @media screen and (max-width: 1024px) {
    --main-header-height: 70px;
    --main-sidebar-padding: 20px;
  }
  @media screen and (max-width: 768px) {
    --main-footer-height: 45px;
  }
`;

interface IMainPage {
  children: React.ReactNode;
}

export const StyledMaxWidthWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: var(--main-max-width);
`;

export const MainPage = ({ children }: IMainPage): JSX.Element => {
  return (
    <StyledWrapper className="wrapper">
      <StyledMaxWidthWrapper> {children}</StyledMaxWidthWrapper>
    </StyledWrapper>
  );
};
