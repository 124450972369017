import React from 'react';
import { CommonPageWrapper } from '@/common/common-page-wrapper';
import { getCurrentLanguage } from '@/i18n';
import UserAgreementsTextUA from '@pages/AuthorizedPage/UserAgreements/text_ua';
import UserAgreementsTextRU from '@pages/AuthorizedPage/UserAgreements/text_ru';
import { useTranslation } from 'react-i18next';

export default function UserAgreements(): JSX.Element {
  useTranslation();
  const lang = getCurrentLanguage();

  return (
    <CommonPageWrapper>
      {lang === 'ua' ? <UserAgreementsTextUA /> : <UserAgreementsTextRU />}
    </CommonPageWrapper>
  );
}
