import styled from "styled-components";

export const UserTexts = styled.div`
  width: 100%;
  margin: 24px 0;
  padding: 30px;
  borderRadius: 10px;
  boxShadow: 0px 7px 10px rgb(0 0 0 / 10%);
  background: #FFF;
`;
