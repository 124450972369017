import styled from 'styled-components';

export const MainHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: var(--main-zIndex-header);

  width: 100%;
  height: var(--main-header-height);
  box-shadow: 0 15px 10px -7px rgba(0, 0, 0, 0.1);
  background: #fff;

  padding-left: var(--main-sidebar-padding);
`;

export const StyledMaxWidthWrapper = styled.div`
  width: 100%;
  max-width: var(--main-max-width);
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 1024px) {
    align-items: center;
    justify-content: space-between;
  }
`;

export const MainHeaderLogo = styled.img`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    width: 36px;
    height: 40px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const MainHeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-left: 1px solid #e1e5eb;
  background: #fff;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    height: 70px;
  }
  p {
    font-size: 18px;
    font-weight: 700;
    color: #5a6169;
  }
`;

export const MainHeaderUserInfo = styled.div`
  display: flex;
  align-items: center;
  width: 196px;
  height: 60px;
  padding-left: 20px;
  border-left: 1px solid #e1e5eb;
  @media screen and (max-width: 1024px) {
    display: none;
  }
  img {
    width: 43px;
    height: 43px;
    border-radius: 50%;
  }
  p {
    margin-left: 9px;
    font-size: 14px;
  }
`;

export const BurgerMenuButton = styled.button`
  display: none;
  @media screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 76px;
    height: 70px;
    border: none;
    border-left: 1px solid #e1e5eb;
    background: none;
    img {
      width: 40px;
      height: 24px;
    }
  }
`;

export const MainContent = styled.div`
  height: calc(100% - 60px);
  padding: 24px;
  background: #f9f9f9;
  overflow-y: auto;
  @media screen and (max-width: 599px) {
    padding: 0;
    background: #fff;
  }
`;
