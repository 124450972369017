import App from '@/App';
import Loader from '@components/Loader';
import AuthLayoutBlock from '@layouts/Auth/AuthLayout';
import { persistor, store } from '@redux/index';
import '@styles/index.scss';
import { rootElement } from '@utils/consts';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import './i18n';
import reportWebVitals from './reportWebVitals';

function Index(): JSX.Element {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <Router>
            <AuthLayoutBlock>
              <App />
            </AuthLayoutBlock>
          </Router>
        </PersistGate>
      </Provider>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.StrictMode>
  );
}

ReactDOM.render(Index(), rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
