import { RootState } from '@/redux';
import { createSelector } from '@reduxjs/toolkit';

export const selectWebinar = (state: RootState): typeof state.webinars =>
  state.webinars;

export const selectCurrentModalWebinarState = createSelector(
  selectWebinar,
  (state) => state.webinarsModal
);

export const selectCurrentModalPurchaseState = createSelector(
  selectWebinar,
  (state) => state.webinarPurchase
);
