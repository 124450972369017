import { IUser } from '@/interfaces/IUser';
import { returnHeaders } from '@/utils/functions';
import { projectApi } from '@redux/api';
import { UserFromResponse } from '../auth';

const ApiType = 'Lectors';
const ApiID = 'LIST';

export type UserResponse = UserFromResponse[];

export type CreateLectorDTO = Omit<IUser, 'avatar' | '_id'>;
export type UpdateLectorDTO = Partial<IUser>;

const extendedApi = projectApi
  .enhanceEndpoints({
    addTagTypes: [ApiType],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getUsers: build.query<UserResponse, void>({
        query: () => {
          const myHeaders = returnHeaders('users');
          return {
            url: 'users',
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          };
        },
        providesTags: (result) => {
          if (result) {
            const arrayOfTags = result.map(({ _id: id }) => ({
              type: ApiType as typeof ApiType,
              id,
            }));
            return [...arrayOfTags, { type: ApiType, id: ApiID }];
          } else return [{ type: ApiType, id: ApiID }];
        },
      }),
      getUser: build.query<UserFromResponse, string>({
        query: (id) => {
          const myHeaders = returnHeaders(`users/${id}`);
          return {
            url: `users/${id}`,
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
          };
        },
        providesTags: (result, error, id) => [{ type: ApiType, id }],
      }),
      updateOtherUserAsAdmin: build.mutation<
        UserFromResponse,
        Partial<UserFromResponse>
      >({
        query(data) {
          const { _id, ...body } = data;
          const myHeaders = returnHeaders(`users/${_id}`);
          return {
            url: `users/${_id}`,
            method: 'PUT',
            redirect: 'follow',
            headers: myHeaders,
            body,
          };
        },
        invalidatesTags: (result, error, { _id: id }) => [
          { type: ApiType, id },
        ],
      }),
      changePreferLanguage: build.mutation<UserFromResponse, void>({
        query() {
          const myHeaders = returnHeaders(`users/change-preffer-language`);
          return {
            url: `users/change-preffer-language`,
            method: 'PATCH',
            redirect: 'follow',
            headers: myHeaders,
          };
        },
      }),
      deleteUser: build.mutation<{ success: boolean; id: number }, string>({
        query(id) {
          const myHeaders = returnHeaders(`users/${id}`);
          return {
            url: `users/${id}`,
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
          };
        },
        invalidatesTags: (result, error, id) => [{ type: ApiType, id }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useDeleteUserMutation,
  useUpdateOtherUserAsAdminMutation,
  useChangePreferLanguageMutation,
} = extendedApi;
