import { FooterItem, FooterMenu } from '@components/Footer/styled';
import React from 'react';
import EmailIcon from '@icons/emailFooter.svg';
import LiqPayLogo from '@icons/liqpay-logo.svg';
import MasterCardLogo from '@icons/master-card-logo.svg';
import Phone1Icon from '@icons/phoneFooter.svg';
import Phone2Icon from '@icons/phoneFooter2.svg';
import VisaLogo from '@icons/visa-logo.svg';
import WebSiteIcon from '@icons/webSite.svg';
import {
  Footer,
  FooterContainer,
  FooterCopy,
  FooterDescription,
} from './styled';
import withAuthType from '@/layouts/Auth/AuthBasedLayout';
import { UserStatusType } from '@/interfaces/IUser';
const RenderFooter = (): JSX.Element => {
  return (
    <FooterMenu>
      <FooterItem>
        <img src={WebSiteIcon} alt={WebSiteIcon} className="icon" />
        <a target="_blank" rel="noreferrer" href="https://glau.com.ua">
          https://glau.com.ua
        </a>
      </FooterItem>
      <FooterItem>
        <img src={EmailIcon} alt={EmailIcon} className="icon" />
        <a href="mailto:glauoffice@ukr.net?subject=Глау Вебiнари">
          glauoffice@ukr.net
        </a>
      </FooterItem>
      <FooterItem>
        <img src={Phone1Icon} alt={Phone1Icon} className="icon" />
        <a href="tel:+380442230281">+ 38 044 223 02 81</a>
      </FooterItem>
      <FooterItem>
        <img src={Phone2Icon} alt={Phone2Icon} className="icon" />
        <a href="tel:+380682230281">+ 38 068 223 02 81</a>
      </FooterItem>
      <FooterItem style={{ flexBasis: '55px!important' }}>
        <img
          src={MasterCardLogo}
          alt={MasterCardLogo}
          style={{ width: '40px' }}
        />
      </FooterItem>
      <FooterItem>
        <img src={VisaLogo} alt={VisaLogo} style={{ height: '16px' }} />
      </FooterItem>
      <FooterItem style={{ marginRight: 0 }}>
        <img
          src={LiqPayLogo}
          alt={LiqPayLogo}
          style={{ height: '18px', marginTop: '2px' }}
        />
      </FooterItem>
    </FooterMenu>
  );
};

const MainFooter = (): JSX.Element => {
  return (
    <Footer>
      <FooterContainer>
        <FooterMenu>
          <RenderFooter />
        </FooterMenu>
        <FooterCopy>
          <FooterDescription>Copyright 2021 URich</FooterDescription>
        </FooterCopy>
      </FooterContainer>
    </Footer>
  );
};

export default withAuthType([UserStatusType.AUTHED, UserStatusType.ADMIN])(
  MainFooter
);
