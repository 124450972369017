import styled from 'styled-components';

export const TileContainer = styled.div`
  height: fit-content;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 15%) 0 9px 25px;
  background: rgb(255, 255, 255);
`;
