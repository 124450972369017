import styled from 'styled-components';

export const FooterMenu = styled.div`
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    display: none;
  }
  /* @media all and (max-width: 768px) and (min-width: 425px) {
    justify-content: center;
    flex-direction: column;
    height: 143px !important;
    margin: 0 calc(100% - 97%);
  }
  @media screen and (max-width: 425px) {
    height: 138px !important;
    margin: 0 calc(100% - 97%);
  } */
`;

export const FooterItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 20px;

  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  flex-shrink: 0;

  @media all and (min-width: 768px) {
    width: 152px;
    flex-basis: 152px;
  }

  .icon {
    width: 18px;
    height: 20px;
    margin-right: 11px;
    align-self: center;
  }

  a {
    align-self: center;
    color: inherit;
  }

  &:nth-child(5) {
    flex-basis: 55px !important;
  }

  &:nth-child(6) {
    flex-basis: 70px !important;
  }

  &:nth-child(7) {
    flex-basis: 90px !important;
  }

  @media screen and (max-width: 768px) {
    //  &:nth-child(5) {
    //    flex-basis: 152px!important;
    //    margin-right: 0!important;
    // }
  }

  @media all and (max-width: 768px) and (min-width: 425px) {
    flex-basis: 2px;

    margin-right: 0 !important;
    &:nth-child(5) {
      flex-basis: 27% !important;
    }
    &:nth-child(6) {
      flex-basis: 27% !important;
    }
    &:nth-child(7) {
      flex-basis: 27% !important;
    }
  }
`;

export const Footer = styled.footer`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: var(--main-zIndex-footer);
  grid-area: footer;
  box-shadow: 0px -7px 14px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  height: var(--main-footer-height);
`;

export const FooterCopy = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #dee2e6;
  @media screen and (max-width: 425px) {
    padding-right: 0;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FooterDescription = styled.p`
  font-size: 14px;
  @media all and (max-width: 768px) and (min-width: 425px) {
    font-size: 12px;
  }
  @media screen and (max-width: 599px) {
    font-size: 10px;
  }
`;
