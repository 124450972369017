import { getCurrentLanguage } from '@/i18n';
import { INotification } from '@/interfaces/INotification';
import { IOrder } from '@/interfaces/IOrder';
import { IUser, UserStatusType } from '@/interfaces/IUser';
import { UserFromResponse } from '@/redux/auth/api';
import {
  AdminRoleStrategy,
  RoleStrategy,
  VisitorRoleStrategy,
} from '../roles/strategy-checker';

class User implements IUser {
  private static instance: User;

  protected readonly userRoleStrategy: RoleStrategy[] = [];
  constructor(
    public notifications_ID: INotification[] = [],
    public orders_ID: IOrder[] = [],
    public id: string = '',
    public name: string = '',
    public surname: string = '',
    public email: string = '',
    public country: string = '',
    public town: string = '',
    public phone: string = '',
    public language: ReturnType<typeof getCurrentLanguage> = 'ua',
    public roles: UserStatusType = UserStatusType.VISITOR
  ) {}

  public static getInstance(): User {
    if (!User.instance) {
      User.instance = new User();
    }

    return User.instance;
  }
  public assignUser(user: UserFromResponse) {
    this.notifications_ID = user.notifications_ID;
    this.orders_ID = user.orders_ID;
    this.id = user._id;
    this.name = user.name;
    this.surname = user.surname;
    this.email = user.email;
    this.country = user.country;
    this.town = user.town;
    this.phone = user.phone;
    this.language = user.preferredLanguage;
    const strategy = this.userRoleStrategy.find(
      (element) => element.role === user.roles[0].value
    ) as RoleStrategy;

    this.roles = strategy.roleSetter();
    return this;
  }
  public assignStrategy(strategy: RoleStrategy) {
    this.userRoleStrategy.push(strategy);
    return this;
  }

  public convertToObject(): IUser {
    const returnedUser: IUser = {
      notifications_ID: this.notifications_ID,
      orders_ID: this.orders_ID,
      id: this.id,
      name: this.name,
      surname: this.surname,
      email: this.email,
      country: this.country,
      phone: this.phone,
      town: this.town,
      roles: this.roles,
      language: this.language,
    };
    return returnedUser;
  }
}

const ourUser = User.getInstance()
  .assignStrategy(new AdminRoleStrategy())
  .assignStrategy(new VisitorRoleStrategy());

export default ourUser;
