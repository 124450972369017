import * as Yup from 'yup';
export const MINIMUM_VALUE = (): string => `Errors.min`;
export const MAXIMUM_VALUE = (): string => `Errors.max`;

export const LENGTH = (): string => `Errors.length`;
export const REG_EXP = (): string => `Errors.regexp`;
export const REQUIRED = (): string => 'Errors.required';
export const EMAIL = (): string => 'Errors.email';
export const EQUAL = (): string => 'Errors.equal';
export const URL = (): string => 'Errors.url';

export const PASSWORD_VALIDATION = Yup.string()
  .required(REQUIRED())
  .min(5, MINIMUM_VALUE())
  .max(16, MAXIMUM_VALUE());
