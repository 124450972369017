import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface ISidebarWrapperProps {
  isLinkMenu: boolean;
}

export const SidebarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 100%;
  z-index: var(--main-zIndex-sidebar);
  box-shadow: 7px 0 14px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  @media screen and (max-width: 1024px) {
    z-index: 9999999999;
    top: 70px;
    left: 0;
    display: ${(props: ISidebarWrapperProps) =>
      props.isLinkMenu ? `block` : `none`};
    width: 100vw;
    height: fit-content;
  }
`;

export const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 20px;
  border-bottom: 1px solid #dee2e6;
  @media screen and (max-width: 1024px) {
    display: none;
  }

  img {
    width: 29px;
    height: 33px;
  }
`;

export const SidebarLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 25px;
  transition: all 300ms ease-out;
  &:hover {
     padding-left: 40px;
  }
  &.${(props) => props.activeClassName} {
    background: #f9f9f9;

    svg path {
      fill: #80bb37;
    }
  }

  svg path {
    fill: #dbdee5;
  }

  p {
    margin-left: 10px;
    font-size: 14px;
    color: #3d5170;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
