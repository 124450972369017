import MainFooter from '@components/Footer';
import Header from '@components/Header';
import { MainPage, StyledOverlay } from '@components/MainContent';
import Sidebar from '@components/Sidebar';
import { ReactComponent as EditIcon } from '@icons/edit.svg';
import { ReactComponent as LectorsIcon } from '@icons/lectors.svg';
import { ReactComponent as OrdersIcon } from '@icons/orders.svg';
import { ReactComponent as PaymentIcon } from '@icons/payment.svg';
import { ReactComponent as UseragreeIcon } from '@icons/useragree.svg';
import { ReactComponent as UsersIcon } from '@icons/users.svg';
import { ReactComponent as WebinarsIcon } from '@icons/webinars.svg';
import { IRoute } from '@interfaces/IRoutes';
import { UserStatusType } from '@interfaces/IUser';
import { LinksEnum } from '@interfaces/LinksEnum';
import {
  LectorsMain,
  NotificationMain,
  OrderPageMain,
  OrdersMain,
  UsersMain,
  WebinarPageMain,
  WebinarsMain,
} from '@pages/AuthorizedPage';
import EditProfile from '@pages/AuthorizedPage/EditProfile';
import PaymentAndDelivery from '@pages/AuthorizedPage/PaymentAndDelivery';
import UserAgreements from '@pages/AuthorizedPage/UserAgreements';
import { MainAuthorized } from '@pages/main';
import { AuthorizedNewPassword, VisitorNewPassword } from '@pages/newpassword';
import {
  ForgotPassword,
  Registration,
  VisitorIntro,
  VisitorLogin,
} from '@pages/VisitorPage';
import { userLoggedStatus } from '@redux/auth';
import { useAppSelector } from '@utils/hooks';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Loader from './components/Loader';

type IRoues = {
  [key in UserStatusType]: IRoute[];
};

const routes: IRoues = {
  [UserStatusType.VISITOR]: [
    {
      name: 'Main',
      path: LinksEnum.MAIN,
      exact: true,
      link: true,
      main: () => <VisitorIntro />,
      sidebar: false,
    },
    {
      name: 'Забыл пароль',
      path: LinksEnum.FORGOT_PASSWORD,
      exact: true,
      link: true,
      main: () => <ForgotPassword />,
      sidebar: false,
    },
    {
      name: 'Registration',
      path: LinksEnum.REGISTRATION,
      exact: true,
      link: true,
      main: () => <Registration />,
      sidebar: false,
    },
    {
      name: 'Новый пароль',
      path: LinksEnum.NEW_PASSWORD,
      exact: true,
      link: true,
      main: () => <VisitorNewPassword />,
      sidebar: false,
    },
    {
      name: 'Login',
      path: LinksEnum.LOGIN,
      exact: true,
      link: true,
      main: () => <VisitorLogin />,
      sidebar: false,
    },
    {
      name: 'Not Match',
      path: LinksEnum.NOTFOUND,
      link: false,
      main: () => <Redirect to={LinksEnum.MAIN} />,
      sidebar: false,
    },
  ],
  [UserStatusType.AUTHED]: [
    {
      name: 'User-content.sidebar.profile',
      path: LinksEnum.MAIN,
      exact: true,
      link: true,
      main: () => (
        <MainPage>
          <MainAuthorized />
        </MainPage>
      ),
      icon: () => <EditIcon />,
      sidebar: true,
    },
    {
      name: 'Новый пароль',
      path: LinksEnum.NEW_PASSWORD,
      exact: true,
      link: true,
      main: () => (
        <MainPage>
          <AuthorizedNewPassword />
        </MainPage>
      ),
      sidebar: false,
    },
    {
      name: 'Редактировать профиль',
      path: LinksEnum.EDIT,
      exact: true,
      sidebar: false,
      link: true,
      main: () => (
        <MainPage>
          <EditProfile />
        </MainPage>
      ),
    },
    {
      name: 'User-content.sidebar.webinars',
      path: LinksEnum.WEBINARS,
      exact: true,
      link: true,
      sidebar: true,
      icon: () => <WebinarsIcon />,
      main: () => (
        <MainPage>
          <WebinarsMain />
        </MainPage>
      ),
    },
    {
      name: 'User-content.sidebar.payments-and-delivery',
      path: LinksEnum.PAYMENT,
      link: true,
      exact: true,
      sidebar: true,
      icon: () => <PaymentIcon />,
      main: () => (
        <MainPage>
          <PaymentAndDelivery />
        </MainPage>
      ),
    },

    {
      name: 'User-content.sidebar.webinars',
      path: LinksEnum.NOTIFICATIONS,
      exact: true,
      link: true,
      sidebar: false,
      icon: () => <WebinarsIcon />,
      main: () => (
        <MainPage>
          <NotificationMain />
        </MainPage>
      ),
    },
    {
      name: 'User-content.sidebar.user-agreements',
      path: LinksEnum.USER_AGREEMENTS,
      link: true,
      exact: true,
      main: () => (
        <MainPage>
          <UserAgreements />
        </MainPage>
      ),
      icon: () => <UseragreeIcon />,
      sidebar: true,
    },
    {
      name: 'Страница вебинара по идентификатору',
      path: `${LinksEnum.WEBINARS}/:id`,
      exact: true,
      link: true,
      main: () => (
        <MainPage>
          <WebinarPageMain />
        </MainPage>
      ),
      icon: () => <WebinarsIcon />,
      sidebar: false,
    },
    {
      name: 'Not Match',
      path: LinksEnum.NOTFOUND,
      link: false,
      main: () => <Redirect to={LinksEnum.MAIN} />,
      sidebar: false,
    },
  ],
  [UserStatusType.ADMIN]: [
    {
      name: 'User-content.sidebar.profile',
      path: LinksEnum.MAIN,
      exact: true,
      link: true,
      sidebar: true,
      main: () => (
        <MainPage>
          <MainAuthorized />
        </MainPage>
      ),
      icon: () => <EditIcon />,
    },
    {
      name: 'Редактировать профиль',
      path: LinksEnum.EDIT,
      exact: true,
      sidebar: false,
      link: true,
      main: () => (
        <MainPage>
          <EditProfile />
        </MainPage>
      ),
    },
    {
      name: 'Новый пароль',
      path: LinksEnum.NEW_PASSWORD,
      exact: true,
      link: true,
      main: () => (
        <MainPage>
          <AuthorizedNewPassword />
        </MainPage>
      ),
      sidebar: false,
    },
    {
      name: 'User-content.sidebar.webinars',
      path: LinksEnum.WEBINARS,
      exact: true,
      link: true,
      sidebar: true,
      icon: () => <WebinarsIcon />,
      main: () => (
        <MainPage>
          <WebinarsMain />
        </MainPage>
      ),
    },
    {
      name: 'User-content.sidebar.webinars',
      path: LinksEnum.NOTIFICATIONS,
      exact: true,
      link: true,
      sidebar: false,
      icon: () => <WebinarsIcon />,
      main: () => (
        <MainPage>
          <NotificationMain />
        </MainPage>
      ),
    },
    {
      name: 'User-content.sidebar.users',
      path: LinksEnum.USERS,
      exact: true,
      link: true,
      main: () => (
        <MainPage>
          <UsersMain />
        </MainPage>
      ),
      icon: () => <UsersIcon />,
      sidebar: true,
    },
    {
      name: 'User-content.sidebar.lectors',
      path: LinksEnum.LECTORS,
      exact: true,
      link: true,
      sidebar: true,
      icon: () => <LectorsIcon />,
      main: () => (
        <MainPage>
          <LectorsMain />
        </MainPage>
      ),
    },
    {
      name: 'Страница вебинара по идентификатору',
      path: `${LinksEnum.WEBINARS}/:id`,
      exact: true,
      link: true,
      main: () => (
        <MainPage>
          <WebinarPageMain />
        </MainPage>
      ),
      sidebar: false,
    },
    {
      name: 'User-content.sidebar.orders',
      path: LinksEnum.ORDERS,
      exact: true,
      link: true,
      main: () => (
        <MainPage>
          <OrdersMain />
        </MainPage>
      ),
      icon: () => <OrdersIcon />,
      sidebar: true,
    },
    {
      name: 'Страница заказа по идентификатору',
      path: `${LinksEnum.ORDERS}/:id`,
      exact: true,
      link: true,
      main: () => (
        <MainPage>
          <OrderPageMain />
        </MainPage>
      ),
      sidebar: false,
    },
    {
      name: 'User-content.sidebar.payments-and-delivery',
      path: LinksEnum.PAYMENT,
      link: true,
      exact: true,
      sidebar: true,
      main: () => (
        <MainPage>
          <PaymentAndDelivery />
        </MainPage>
      ),
      icon: () => <PaymentIcon />,
    },
    {
      name: 'User-content.sidebar.user-agreements',
      path: LinksEnum.USER_AGREEMENTS,
      link: true,
      exact: true,
      main: () => (
        <MainPage>
          <UserAgreements />
        </MainPage>
      ),
      icon: () => <UseragreeIcon />,
      sidebar: true,
    },
    {
      name: 'Not Match',
      path: LinksEnum.NOTFOUND,
      link: false,
      main: () => <Redirect to={LinksEnum.MAIN} />,
      sidebar: false,
    },
  ],
};

function App(): JSX.Element {
  const location = useLocation();
  const type = useAppSelector(userLoggedStatus);

  return (
    <StyledOverlay>
      <Header />
      <Sidebar routes={routes[type]} />
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={location.pathname}
          classNames="my-node"
          timeout={300}
        >
          <Suspense fallback={<Loader />}>
            <Switch location={location}>
              {routes[type]?.map((OurRoute, index, Routes) => (
                <Route key={index} path={OurRoute.path} exact={OurRoute.exact}>
                  <OurRoute.main routes={Routes} />
                </Route>
              ))}
            </Switch>
          </Suspense>
        </CSSTransition>
      </SwitchTransition>
      <MainFooter />
    </StyledOverlay>
  );
}

export default App;
