import styled from 'styled-components';

export const StyledButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 25px;
  transition: all 300ms ease-out;
  background: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    transform: translate(10px);
  }

  svg path {
    fill: #dbdee5;
  }

  p {
    font-family: 'Roboto Medium', sans-serif;
    margin-left: 10px;
    font-size: 14px;
    color: #3d5170;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
