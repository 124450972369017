import { CommonPageWrapper } from '@/common/common-page-wrapper';
import { getCurrentLanguage } from '@/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PaymentDelRU from './text_ru';
import PaymentDelUA from './text_ua';

const PaymentAndDelivery = (): JSX.Element => {
  useTranslation();

  const lang = getCurrentLanguage();

  return (
    <CommonPageWrapper>
      {lang === 'ua' ? <PaymentDelUA /> : <PaymentDelRU />}
    </CommonPageWrapper>
  );
};

export default PaymentAndDelivery;
