import styled from 'styled-components';

export const UserContainer = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 45px 0;
  @media screen and (max-width: 1440px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 45px;
  }
`;

export const UserInfoPhoto = styled.img`
  width: 160px;
  height: 160px;
  @media screen and (max-width: 599px) {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
  }
`;

export const UserDataOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  @media screen and (max-width: 1440px) {
    width: 100%;
    margin-top: 22px;
  }
`;

export const UserDataOptions = styled.form`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 17px 45px;
  margin-bottom: 60px;
  @media screen and (max-width: 1280px) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 1024px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 834px) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 599px) {
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    grid-gap: 22px 0;
  }
`;

export const UserDataOption = styled.div`
  display: flex;
  flex-direction: column;
  .special-label {
    display: none;
  }
  label {
    margin-bottom: 7px;
    font-size: 14px;
    color: #181d27;
  }
  input {
    height: 40px;
    padding: 12px 15px;
    border: 1px solid #dbdee5;
    border-radius: 4px;
    font-size: 14px;
  }
`;

export const UserDataSelectorOption = styled.div`
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 7px;
    font-size: 14px;
    color: #181d27;
  }
`;

export const EditProfileSaveButton = styled.button`
  max-height: 40px;
  min-width: 90px;
  padding: 12px 15px;
  background: linear-gradient(180deg, #80bb37 0%, #428e2a 100%);
  border: none;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  align-self: flex-end;

  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 599px) {
    width: 100%;
  }
`;
