import React from "react";
import {TileContainer} from "@/common/common-tile-container/styles";
import {ICommonTileContainerProps} from "@/common/common-tile-container/types";

export const CommonTileContainer: React.FC<ICommonTileContainerProps> = ({
  children,
  className
}) => {
  return (
    <TileContainer className={className}>{children}</TileContainer>
  );
};
