import {
  Action,
  configureStore,
  ThunkAction,
  combineReducers,
} from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authReducer } from './auth';
import { cssSlice } from './css';
import { projectApi } from './api';
import { webinarsSlice } from './webinars';
import { lectorSlice } from './lectors';
import { usersSlice } from './users';
import { notificationSlice } from './notification';
import './orders';

import { setupListeners } from '@reduxjs/toolkit/dist/query';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['GLAU-api'],
};

const reducers = combineReducers({
  auth: authReducer,
  css: cssSlice,
  webinars: webinarsSlice,
  lectors: lectorSlice,
  users: usersSlice,
  notifications: notificationSlice,
  [projectApi.reducerPath]: projectApi.reducer,
});

const persistedReducer = persistReducer(
  persistConfig,
  reducers
) as unknown as typeof reducers;

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(projectApi.middleware),
});
setupListeners(store.dispatch);

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
