import { IWebinar } from '@/interfaces/IWebinar';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

type ModalState = string | '' | undefined;
type PurchaseState = string | undefined;

export enum EWebinarsModalStrategy {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  VIDEO = 'VIDEO',
  CLOSED = 'CLOSED',
}
export enum EWebinarsPurchaseStrategy {
  PURCHASE = 'PURCHASE',
  CLOSED = 'CLOSED',
}

interface WebinarState {
  webinarsData: IWebinar[];
  webinarsModal: ModalState;
  webinarPurchase: PurchaseState;
}

const initialState: WebinarState = {
  webinarsData: [],
  webinarsModal: undefined,
  webinarPurchase: undefined,
};

export const webinarsSlice = createSlice({
  name: 'webinars',
  initialState,
  reducers: {
    openWebinarsPurchaseModal: (
      state,
      action: PayloadAction<{
        price: IWebinar['price'];
        id: IWebinar['_id'];
      }>
    ) => {
      state.webinarPurchase = `${EWebinarsPurchaseStrategy.PURCHASE}-${action.payload.id}-${action.payload.price}`;
    },
    closeWebinarsPurchaseModal: (state) => {
      state.webinarPurchase = undefined;
    },
    openWebinarsEditModal: (state, action: PayloadAction<IWebinar['_id']>) => {
      state.webinarsModal = `${EWebinarsModalStrategy.EDIT}-${action.payload}`;
    },
    openWebinarsVideoModal: (state, action: PayloadAction<IWebinar['_id']>) => {
      state.webinarsModal = `${EWebinarsModalStrategy.VIDEO}-${action.payload}`;
    },
    openWebinarsCreateModal: (state) => {
      state.webinarsModal = '';
    },
    closeWebinarModal: (state) => {
      state.webinarsModal = undefined;
    },
    clearWebinarState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  openWebinarsPurchaseModal,
  closeWebinarsPurchaseModal,
  clearWebinarState,
  openWebinarsEditModal,
  openWebinarsCreateModal,
  openWebinarsVideoModal,
  closeWebinarModal,
} = webinarsSlice.actions;

export default webinarsSlice.reducer;
