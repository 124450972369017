import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationUA from '@locales/translation-ua.json';
import translationRU from '@locales/translation-ru.json';

const resources = {
  ua: {
    translation: translationUA,
  },
  ru: {
    translation: translationRU,
  },
};

const lng = 'ua';

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng,
    interpolation: {
      escapeValue: false,
    },
  });

export type CurrentLanguage = 'ua' | 'ru';

export const getCurrentLanguage = (): CurrentLanguage => {
  return (
    i18n.resolvedLanguage ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    'en'
  );
};

export default i18n;
