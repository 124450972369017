import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { clearAuthState } from '../auth';

export enum Environment {
  DEVELOPMENT = 'development',
  STAGE = 'stage',
  PRODUCTION = 'production',
}

export function getEnvironment(): Environment {
  const domain = window.location.hostname;
  if (domain.includes('webinars-front-dev.glau.com.ua')) {
    return Environment.DEVELOPMENT;
  } else if (domain.includes('localhost')) {
    return Environment.STAGE;
  } else if (domain.includes('webinars.glau.com.ua')) {
    return Environment.PRODUCTION;
  }
  return Environment.PRODUCTION;
}

export const BASE_URL =
  {
    [Environment.DEVELOPMENT]: '/development',
    [Environment.STAGE]: '/development',
    [Environment.PRODUCTION]: '/production',
  }[getEnvironment()] ?? '/production';

const baseQuery = fetchBaseQuery({ baseUrl: BASE_URL ?? '/production' });
const baseQueryWithLogout: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(clearAuthState());
  }
  return result;
};
export const projectApi = createApi({
  reducerPath: 'GLAU-api',
  baseQuery: baseQueryWithLogout,
  endpoints: () => ({}),
});
