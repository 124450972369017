import { UserStatusType } from '@interfaces/IUser';

export enum UserRolesTypeFromResponse {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface RoleStrategy {
  role: UserRolesTypeFromResponse;
  roleSetter(): UserStatusType;
}

export class AdminRoleStrategy implements RoleStrategy {
  public role: UserRolesTypeFromResponse = UserRolesTypeFromResponse.ADMIN;
  public roleSetter(): UserStatusType {
    return UserStatusType.ADMIN;
  }
}
export class VisitorRoleStrategy implements RoleStrategy {
  public role: UserRolesTypeFromResponse = UserRolesTypeFromResponse.USER;
  public roleSetter(): UserStatusType {
    return UserStatusType.AUTHED;
  }
}
