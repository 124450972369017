/**
 * Функция итерируется по обьекту и делает глубокую копию пригодную для сохранения в FormData
 * @param object
 * @returns {FormData}
 */

export function Object2FormData<T>(object: T): FormData {
  return Object.entries(object).reduce((accum, [key, value]) => {
    if (value instanceof File) {
      accum.append(key, value);
    } else {
      accum.append(key, JSON.stringify(value));
    }
    return accum;
  }, new FormData());
}
