import { RootState } from '@/redux';
import { createSelector } from '@reduxjs/toolkit';

export const selectLector = (state: RootState): typeof state.lectors =>
  state.lectors;

export const selectCurrentModalState = createSelector(
  selectLector,
  (state) => state.lectorsModal
);
