import { IUser } from '@/interfaces/IUser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

type ModalState = IUser['id'] | undefined;

interface LectorState {
  usersData: IUser[];
  usersModal: ModalState;
}

const initialState: LectorState = {
  usersData: [],
  usersModal: undefined,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    openUserEditModal: (state, action: PayloadAction<IUser['id']>) => {
      state.usersModal = action.payload;
    },
    closeUserModal: (state) => {
      state.usersModal = undefined;
    },
    clearUserState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { openUserEditModal, closeUserModal, clearUserState } =
  usersSlice.actions;

export default usersSlice.reducer;
