export const RegCountries = [
  { value: 'UA', label: 'Україна' },
  { value: 'RU', label: 'Росія' }
];
export const RegCountriesRu = [
  { value: 'UA', label: 'Украина' },
  { value: 'RU', label: 'Россия' }
];

export const RegUkrRegions = [
  { value: '43', label: 'Автономна Республіка Крим' },
  { value: '71', label: 'Черкаська область' },
  { value: '74', label: 'Чернігівська область' },
  { value: '77', label: 'Чернівецька область' },
  { value: '12', label: 'Дніпропетровська область' },
  { value: '14', label: 'Донецька область' },
  { value: '26', label: 'Івано-Франківська область' },
  { value: '63', label: 'Харківська область' },
  { value: '65', label: 'Херсонська область' },
  { value: '68', label: 'Хмельницька область' },
  { value: '30', label: 'Київ' },
  { value: '35', label: 'Кіровоградська область' },
  { value: '32', label: 'Київська область' },
  { value: '09', label: 'Луганська область' },
  { value: '46', label: 'Львівська область' },
  { value: '48', label: 'Миколаївська область' },
  { value: '51', label: 'Одеська область' },
  { value: '56', label: 'Рівненська область' },
  { value: '59', label: 'Сумська область' },
  { value: '61', label: 'Тернопільська область' },
  { value: '05', label: 'Вінницька область' },
  { value: '07', label: 'Волинська область' },
  { value: '21', label: 'Закарпатська область' },
  { value: '23', label: 'Запорізька область' },
  { value: '18', label: 'Житомирська область' }
];
export const RegUkrRegionsRU = [
  { value: '43', label: 'Автономная Республика Крым' },
  { value: '71', label: 'Черкасская область' },
  { value: '74', label: 'Черниговская область' },
  { value: '77', label: 'Черновицкая область' },
  { value: '12', label: 'Днепропетровская область' },
  { value: '14', label: 'Донецкая область' },
  { value: '26', label: 'Ивано-Франковская область' },
  { value: '63', label: 'Харьковская область' },
  { value: '65', label: 'Херсонская область' },
  { value: '68', label: 'Хмельницкая область' },
  { value: '30', label: 'Киев' },
  { value: '35', label: 'Кировоградская область' },
  { value: '32', label: 'Киевская область' },
  { value: '09', label: 'Луганская область' },
  { value: '46', label: 'Львовская область' },
  { value: '48', label: 'Николаевская область' },
  { value: '51', label: 'Одесская область' },
  { value: '56', label: 'Ровенская область' },
  { value: '59', label: 'Сумская область' },
  { value: '61', label: 'Тернопольская область' },
  { value: '05', label: 'Винницкая область' },
  { value: '07', label: 'Волынская область' },
  { value: '21', label: 'Закарпатская область' },
  { value: '23', label: 'Запорожская область' },
  { value: '18', label: 'Житомирская область' }
];

export const RegRusRegions = [
  { value: 'ALT', label: 'Алтайский край' },
  { value: 'AL', label: 'Республика Алтай' },
  { value: 'AMU', label: 'Амурская область' },
  { value: 'ARK', label: 'Архангельск' },
  { value: 'AST', label: 'Астраханская область' },
  { value: 'BEL', label: 'Белгородская область' },
  { value: 'BRY', label: 'Брянская область' },
  { value: 'CE', label: 'Чеченская Республика' },
  { value: 'CHE', label: 'Челябинская область' },
  { value: 'CHU', label: 'Чукотский автономный округ' },
  { value: 'CU', label: 'Чувашская республика' },
  { value: 'IRK', label: 'Иркутск' },
  { value: 'IVA', label: 'Ивановская область' },
  { value: 'YEV', label: 'Еврейская автономная область' },
  { value: 'KB', label: 'Кабардино-Балкарская Республика' },
  { value: 'KGD', label: 'Калининград' },
  { value: 'KLU', label: 'Калужская область' },
  { value: 'KAM', label: 'Камчатский край' },
  { value: 'KC', label: 'Карачаево-Черкесская Республика' },
  { value: 'KEM', label: 'Кемеровская область' },
  { value: 'KHA', label: 'Хабаровский край' },
  { value: 'KHM', label: 'Ханты-Мансийский автономный округ' },
  { value: 'KIR', label: 'Кировская область' },
  { value: 'KO', label: 'Республика Коми' },
  { value: 'KOS', label: 'Костромская область' },
  { value: 'KDA', label: 'Краснодарский край' },
  { value: 'KYA', label: 'Красноярский край' },
  { value: 'KGN', label: 'Курганская область' },
  { value: 'KRS', label: 'Курская область' },
  { value: 'LEN', label: 'Ленинградская область' },
  { value: 'LIP', label: 'Липецкая область' },
  { value: 'MAG', label: 'Магаданская область' },
  { value: 'ME', label: 'Республика Марий Эл' },
  { value: 'MOW', label: 'Москва' },
  { value: 'MOS', label: 'Московская область' },
  { value: 'MUR', label: 'Мурманская область' },
  { value: 'NEN', label: 'Ненецкий автономный округ' },
  { value: 'NIZ', label: 'Нижегородская область' },
  { value: 'NGR', label: 'Новгородская область' },
  { value: 'NVS', label: 'Новосибирск' },
  { value: 'OMS', label: 'Омская область' },
  { value: 'ORE', label: 'Оренбургская область' },
  { value: 'ORL', label: 'Орловская область' },
  { value: 'PNZ', label: 'Пензенская область' },
  { value: 'PER', label: 'Пермский край' },
  { value: 'PRI', label: 'Приморский край' },
  { value: 'PSK', label: 'Псковская область' },
  { value: 'AD', label: 'Республика Адыгея' },
  { value: 'BA', label: 'Республика Башкортостан' },
  { value: 'BU', label: 'Республика Бурятия' },
  { value: 'DA', label: 'Республика Дагестан' },
  { value: 'IN', label: 'Республика Ингушетия' },
  { value: 'KL', label: 'Республика Калмыкия' },
  { value: 'KR', label: 'Республика Карелия' },
  { value: 'KK', label: 'Республика Хакасия' },
  { value: 'MO', label: 'Республика Мордовия' },
  { value: 'SE', label: 'Республика Северная Осетия — Алания' },
  { value: 'TA', label: 'Республика Татарстан' },
  { value: 'ROS', label: 'Ростовская область' },
  { value: 'RYA', label: 'Рязанская область' },
  { value: 'SPE', label: 'Санкт-Петербург' },
  { value: 'SA', label: 'Республика Саха' },
  { value: 'SAK', label: 'Сахалин' },
  { value: 'SAM', label: 'Самарская область' },
  { value: 'SAR', label: 'Саратовская область' },
  { value: 'SMO', label: 'Смоленская область' },
  { value: 'STA', label: 'Ставропольский край' },
  { value: 'SVE', label: 'Свердловск' },
  { value: 'TAM', label: 'Тамбовская область' },
  { value: 'TOM', label: 'Томская область' },
  { value: 'TUL', label: 'Тульская область' },
  { value: 'TY', label: 'Республика Тыва' },
  { value: 'TVE', label: 'Тверская область' },
  { value: 'TYU', label: 'Тюменская область' },
  { value: 'UD', label: 'Удмуртская Республика' },
  { value: 'ULY', label: 'Ульяновская область' },
  { value: 'VLA', label: 'Владимирская область' },
  { value: 'VLG', label: 'Вологодская область' },
  { value: 'VOR', label: 'Воронежская область' },
  { value: 'YAN', label: 'Ямало-Ненецкий автономный округ' },
  { value: 'YAR', label: 'Ярославская область' },
  { value: 'ZAB', label: 'Забайкальский край' }
];

export const RegRusRegionsUA = [
  { value: 'ALT', label: 'Алтайський край' },
  { value: 'AL', label: 'Республіка Алтай' },
  { value: 'AMU', label: 'Амурська область' },
  { value: 'ARK', label: "Архангельськ" },
  { value: 'AST', label: 'Астраханська область' },
  { value: 'BEL', label: 'Бєлгородська область' },
  { value: 'BRY', label: 'Брянська область' },
  { value: 'CE', label: 'Чеченська Республіка' },
  { value: 'CHE', label: 'Челябінська область' },
  { value: 'CHU', label: 'Чукотський автономний округ' },
  { value: 'CU', label: 'Чуваська Республіка' },
  { value: 'IRK', label: 'Іркутськ' },
  { value: 'IVA', label: 'Івановська область' },
  { value: 'YEV', label: 'Єврейська автономна область' },
  { value: 'KB', label: 'Кабардино-Балкарська Республіка' },
  { value: 'KGD', label: 'Калінінград' },
  { value: 'KLU', label: 'Калузька область' },
  { value: 'KAM', label: 'Камчатський край' },
  { value: 'KC', label: 'Карачаєво-Черкеська Республіка' },
  { value: 'KEM', label: 'Кемеровська область' },
  { value: 'KHA', label: 'Хабаровський край' },
  { value: 'KHM', label: 'Ханти-Мансійський автономний округ' },
  { value: 'KIR', label: 'Кіровська область' },
  { value: 'KO', label: 'Республіка Комі' },
  { value: 'KOS', label: 'Костромська область' },
  { value: 'KDA', label: 'Краснодарський край' },
  { value: 'KYA', label: 'Красноярський край' },
  { value: 'KGN', label: 'Курганська область' },
  { value: 'KRS', label: 'Курська область' },
  { value: 'LEN', label: 'Ленінградська область' },
  { value: 'LIP', label: 'Ліпецька область' },
  { value: 'MAG', label: 'Магаданська область' },
  { value: 'ME', label: 'Республіка Марій Ел' },
  { value: 'MOW', label: 'Москва' },
  { value: 'MOS', label: 'Московська область' },
  { value: 'MUR', label: 'Мурманська область' },
  { value: 'NEN', label: 'Ненецький автономний округ' },
  { value: 'NIZ', label: 'Нижньогородська область' },
  { value: 'NGR', label: 'Новгородська область' },
  { value: 'NVS', label: 'Новосибірськ' },
  { value: 'OMS', label: 'Омська область' },
  { value: 'ORE', label: 'Оренбурзька область' },
  { value: 'ORL', label: 'Орловська область' },
  { value: 'PNZ', label: 'Пензенська область' },
  { value: 'PER', label: 'Пермський край' },
  { value: 'PRI', label: 'Приморський край' },
  { value: 'PSK', label: 'Псковська область' },
  { value: 'AD', label: 'Республіка Адигея' },
  { value: 'BA', label: 'Республіка Башкортостан' },
  { value: 'BU', label: 'Республіка Бурятія' },
  { value: 'DA', label: 'Республіка Дагестан' },
  { value: 'IN', label: 'Республіка Інгушетія' },
  { value: 'KL', label: 'Республіка Калмикія' },
  { value: 'KR', label: 'Республіка Карелія' },
  { value: 'KK', label: 'Республіка Хакасія' },
  { value: 'MO', label: 'Республіка Мордовія' },
  { value: 'SE', label: 'Республіка Північна Осетія — Аланія' },
  { value: 'TA', label: 'Республіка Татарстан' },
  { value: 'ROS', label: 'Ростовська область' },
  { value: 'RYA', label: 'Рязанська область' },
  { value: 'SPE', label: 'Санкт-Петербург' },
  { value: 'SA', label: 'Республіка Саха' },
  { value: 'SAK', label: 'Сахалін' },
  { value: 'SAM', label: 'Самарська область' },
  { value: 'SAR', label: 'Саратовська область' },
  { value: 'SMO', label: 'Смоленська область' },
  { value: 'STA', label: 'Ставропольський край' },
  { value: 'SVE', label: '"Свердловськ"' },
  { value: 'TAM', label: 'Тамбовська область' },
  { value: 'TOM', label: 'Томська область' },
  { value: 'TUL', label: 'Тульська область' },
  { value: 'TY', label: 'Республіка Тува' },
  { value: 'TVE', label: 'Тверська область' },
  { value: 'TYU', label: 'Тюменська область' },
  { value: 'UD', label: 'Удмуртська Республіка' },
  { value: 'ULY', label: 'Ульяновська область' },
  { value: 'VLA', label: 'Владимирська область' },
  { value: 'VLG', label: 'Вологодська область' },
  { value: 'VOR', label: 'Воронезька область' },
  { value: 'YAN', label: 'Ямало-Ненецький автономний округ' },
  { value: 'YAR', label: 'Ярославська область' },
  { value: 'ZAB', label: 'Забайкальський край' }
];
