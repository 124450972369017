import { IOrder } from '@/interfaces/IOrder';
import { IWebinar } from '@/interfaces/IWebinar';
import { returnHeaders } from '@/utils/functions';
import { projectApi } from '@redux/api';
import { UserFromResponse } from '../auth';
const ApiType = 'Orders';
const ApiID = 'LIST';

export type OrdersResponse = IOrder[];
export type OrderResponse = [IOrder] | [];

export type OrderCreateResponse = IOrder;
export type OrderCreate = {
  webinarID: IWebinar['_id'];
  userID: UserFromResponse['_id'];
};

const extendedApi = projectApi
  .enhanceEndpoints({
    addTagTypes: [ApiType],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getOrders: build.query<OrdersResponse, void>({
        query: () => {
          const myHeaders = returnHeaders('order-logic');
          return {
            url: 'order-logic',
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          };
        },
        providesTags: (result) => {
          if (result) {
            const arrayOfTags = result.map(({ userID, webinarID }) => ({
              type: ApiType as typeof ApiType,
              id: `${userID._id}${webinarID._id}`,
            }));
            return [...arrayOfTags, { type: ApiType, id: ApiID }];
          } else return [{ type: ApiType, id: ApiID }];
        },
      }),
      getOrder: build.query<OrderResponse, string>({
        query: (id) => {
          const myHeaders = returnHeaders(`order-logic/${id}`);
          return {
            url: `order-logic/${id}`,
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
          };
        },
        providesTags: (result, error, id) => {
          if (result && result[0]) {
            const order = result[0];
            return [
              {
                type: ApiType,
                id: `${order.userID._id}${order.webinarID._id}`,
              },
            ];
          } else return [{ type: ApiType, id }];
        },
      }),
      createOrUpdateOrder: build.mutation<OrderCreateResponse, OrderCreate>({
        query(data) {
          const myHeaders = returnHeaders(`order-logic/create-order`);
          return {
            url: `order-logic/create-order`,
            method: 'POST',
            redirect: 'follow',
            headers: myHeaders,
            body: data,
          };
        },
        invalidatesTags: (result, error, data) => {
          return [{ type: ApiType, id: `${data.userID}${data.webinarID}` }];
        },
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetOrderQuery,
  useGetOrdersQuery,
  useCreateOrUpdateOrderMutation,
} = extendedApi;
