import React from 'react';
import { UserTexts } from '../UserAgreements/styled';

const PaymentDelRU = (): JSX.Element => (
  <UserTexts>
    <h2>Условия оплаты и доступа к вебинаров</h2>
    <br />
    <p>Оплата</p>
    <br />
    <p>1. Безналичный расчет карточкой онлайн через ситему LiqPay</p>
    <p>
      2. Безналичный расчет по реквизитам, в случае уплаты от юридического лица.
    </p>
    <br />
    <p>Доступ к вебинаров:</p>
    <br />
    <p>
      1. При оплате картой доступ к вебинаров предоставляется после успешной
      оплаты сроком на 30 дней.
    </p>
    <p>
      2. При оплате безналичным расчетом доступ предоставляется после
      подтверждения бухгалтерией вашей оплаты, сроком на 30 дней.
    </p>
    <p>
      3. По истечении срока доступа, Вы можете продолжить его за пол стоимости
      вебинара сроком на 30 дней.
    </p>
  </UserTexts>
);

export default PaymentDelRU;
