import { ILector } from '@/interfaces/ILector';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

type ModalState = ILector['_id'] | '' | undefined;

interface LectorState {
  notificationsData: ILector[];
  notificationsModal: ModalState;
}

const initialState: LectorState = {
  notificationsData: [],
  notificationsModal: undefined,
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    openLectorsEditModal: (state, action: PayloadAction<ILector['_id']>) => {
      state.notificationsModal = action.payload;
    },
    openLectorsCreateModal: (state) => {
      state.notificationsModal = '';
    },
    closeLectorModal: (state) => {
      state.notificationsModal = undefined;
    },
    clearLectorState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  clearLectorState,
  openLectorsCreateModal,
  closeLectorModal,
  openLectorsEditModal,
} = notificationSlice.actions;

export default notificationSlice.reducer;
