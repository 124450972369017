import { getCurrentLanguage } from '@/i18n';
import { IUser, UserStatusType } from '@interfaces/IUser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ourUser from '@utils/user/user-converter';
import { PURGE } from 'redux-persist';
import { UserFromResponse } from './api';

type AuthStateData = IUser;

export interface AuthState {
  data: AuthStateData;
  pending: boolean;
  error: boolean;
}

const initialState: AuthState = {
  data: {
    notifications_ID: [],
    orders_ID: [],
    id: '',
    name: '',
    surname: '',
    email: '',
    country: '',
    town: '',
    phone: '',
    language: 'ua',
    roles: UserStatusType.VISITOR,
  },
  pending: false,
  error: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthState: () => initialState,
    setLanguage: (
      state,
      action: PayloadAction<ReturnType<typeof getCurrentLanguage>>
    ) => {
      state.data.language = action.payload;
    },
    setUserInstance: (state, action: PayloadAction<UserFromResponse>) => {
      state.data = ourUser.assignUser(action.payload).convertToObject();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});
export const { clearAuthState, setUserInstance } = authSlice.actions;

export default authSlice.reducer;
