import React from 'react';
import { UserTexts } from '@pages/AuthorizedPage/UserAgreements/styled';

const UserAgreementsTextRU = (): JSX.Element => (
  <UserTexts>
    <h2>Пользовательское соглашение</h2>
    <br />
    <p>
      Данная Политика конфиденциальности персональных данных (далее - Политика
      конфиденциальности) действует в отношении всей информации, которую
      Общественная организация «Гильдия ландшафтных архитекторов Украины» (ОО
      ГЛАУ), расположенная на доменном имени glau.com.ua, может получить о
      пользователе во время использования сайта webinars.glau.com.ua, продуктов
      ГЛАУ.
    </p>
    <br />
    <p>1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
    <br />
    <p>
      1.1. Использование Пользователем сайта означает согласие с этой Политикой
      конфиденциальности и условиями обработки персональных данных пользователя.
    </p>
    <p>
      1.2. В случае несогласия с условиями Политики конфиденциальности
      Пользователь должен прекратить использование сайта.
    </p>
    <p>
      1.3. Данная Политика конфиденциальности применяется только к сайту
      webinars.glau.com.ua.
    </p>
    <p>
      1.4. Администрация сайта не проверяет достоверность персональных данных,
      предоставляемых Пользователем сайта.
    </p>
    <br />
    <p>2. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</p>
    <br />
    <p>
      2.1. Данная Политика конфиденциальности устанавливает обязательства
      Администрации сайта по неразглашению и обеспечению режима защиты
      конфиденциальности персональных данных, которые Пользователь предоставляет
      по запросу Администрации сайта при регистрации на сайте или при оформлении
      заказа на покупку товара.
    </p>
    <p>
      2.2. Персональные данные, разрешенные к обработке в рамках этой Политики
      конфиденциальности, предоставляются Пользователем путем заполнения
      регистрационной формы на Сайте в разделе Личный Кабинет и включают в себя
      следующую информацию:
    </p>
    <p>2.2.1. фамилия, имя, отчество Пользователя</p>
    <p>2.2.2. контактный телефон Пользователя</p>
    <p>2.2.3. адрес электронной почты (e-mail);</p>
    <p>2.2.4. город проживания пользователя.</p>
    <p>
      2.3. Интернет-магазин защищает Данные, которые автоматически передаются в
      процессе просмотра рекламных блоков и при посещении страниц, на которых
      установлено статистический скрипт системы ( &apos;пиксель&apos;):
    </p>
    <p>• IP адрес;</p>
    <p>• информация по cookies;</p>
    <p>
      • информация о браузере (или другую программу, которая осуществляет доступ
      к показу рекламы);
    </p>
    <p>• время доступа</p>
    <p>• адрес страницы, на которой расположен рекламный блок;</p>
    <p>• реферер (адрес предыдущей страницы).</p>
    <p>
      2.3.1. Отключение cookies может повлечь невозможность доступа к частям
      сайта Интернет-магазина, требующих авторизации.
    </p>
    <p>
      2.3.2. Интернет-магазин осуществляет сбор статистики об IP-адресах своих
      посетителей. Данная информация используется для выявления и решения
      технических проблем, для контроля законности проводимых финансовых
      платежей.
    </p>
    <p>
      2.4. Любая другая персональная информация не оговорено выше (история
      покупок, используемые браузеры и операционные системы и т.д.) подлежит
      надежном хранению и нераспространению, за исключением случаев,
      предусмотренных в п 5.2. и 5.3. этой Политики конфиденциальности.
    </p>
    <br />
    <p>3. ЦЕЛИ СБОРА персональной информации пользователей</p>
    <br />
    <p>
      3.1. Персональные данные пользователя Администрация сайта может
      использовать в целях:
    </p>
    <p>
      3.1.1. Идентификации пользователя, зарегистрированного на сайте, для
      оформления заказа.
    </p>
    <p>
      3.1.2. Предоставление Пользователю доступа к персонализированным ресурсов
      Сайта.
    </p>
    <p>
      3.1.3. Установление с пользователем обратной связи, включая направление
      сообщений, запросов, касающихся использования сайта интернет-магазина,
      оказание услуг, обработка запросов и заявок от пользователя.
    </p>
    <p>
      3.1.4. Определение местонахождения пользователя для обеспечения
      безопасности, предотвращения мошенничества.
    </p>
    <p>
      3.1.5. Подтверждения достоверности и полноты персональных данных,
      предоставленных Пользователем.
    </p>
    <p>
      3.1.6. Создание учетной записи для совершения покупок, если Пользователь
      дал согласие на создание учетной записи.
    </p>
    <p>
      3.1.7. Сообщение пользователя сайта интернет-магазина о состоянии Заказ.
    </p>
    <p>
      3.1.8. Обработки и получения платежей, подтверждение налога или налоговых
      льгот, оспаривания платежа, определения права на получение кредитной линии
      пользователем.
    </p>
    <p>
      3.1.9. Предоставление Пользователю эффективной клиентской и технической
      поддержки при возникновении проблем, связанных с использованием сайта
      интернет-магазина.
    </p>
    <p>
      3.1.10. Предоставление Пользователю с его согласия, обновлений продукции,
      специальных предложений, информации о ценах, рассылки новостей и других
      сведений от имени Интернет-магазина или от имени партнеров
      Интернет-магазина.
    </p>
    <br />
    <p>5. ОБЯЗАННОСТИ СТОРОН</p>
    <br />
    <p>5.1. Пользователь обязан:</p>
    <p>
      5.1.1. Предоставить информацию о персональных данных, необходимую для
      пользования Сайтом интернет-магазина.
    </p>
    <p>
      5.1.2. Обновить, дополнить предоставленную информацию о персональных
      данных в случае изменения данной информации.
    </p>
    <p>5.2. Администрация сайта обязана:</p>
    <p>
      5.2.1. Использовать полученную информацию исключительно для целей,
      указанных в п. 3 этой Политики конфиденциальности.
    </p>
    <p>
      5.2.2. Обеспечить хранение конфиденциальной информации в тайне, не
      разглашать без предварительного письменного согласия пользователя, а также
      не осуществлять продажу, обмен, опубликованные или разглашение другими
      возможными способами переданных персональных данных пользователя, за
      исключением п. 4.2. этой Политики конфиденциальности.
    </p>
    <p>
      5.2.3. Принимать меры предосторожности для защиты конфиденциальности
      персональных данных пользователя согласно порядку, который обычно
      используется для защиты такого рода информации в существующем деловом
      обороте.
    </p>
    <p>
      5.2.4. Осуществить блокировку персональных данных, относящихся к
      соответствующему пользователя, с момента обращения или запроса
      пользователя или его законного представителя или уполномоченного органа по
      защите прав субъектов персональных данных на период проверки, в случае
      выявления недостоверных персональных данных или неправомерных действий.
    </p>
    <br />
    <p>6. ОТВЕТСТВЕННОСТЬ СТОРОН</p>
    <br />
    <p>
      6.1. Администрация сайта, не выполнила свои обязательства, несет
      ответственность за убытки, понесенные Пользователем в связи с
      неправомерным использованием персональных данных, в соответствии с
      законодательством Украины, за исключением случаев, предусмотренных п.п.
      4.2. и 6.2. этой Политики конфиденциальности.
    </p>
    <p>
      6.2. В случае потери или разглашение Конфиденциальной информации
      Администрация сайта не несет ответственность, если данная конфиденциальная
      информация:
    </p>
    <p>6.2.1. Стала публичным достоянием к ее потере или разглашения.</p>
    <p>
      6.2.2. Была получена от третьей стороны до момента ее получения
      Администрацией сайта.
    </p>
    <p>6.2.3. Была разглашена с согласия пользователя.</p>
    <br />
    <p>7. РАЗРЕШЕНИЕ СПОРОВ</p>
    <br />
    <p>
      7.1. К обращению в суд с иском по спорам, возникающим из отношений между
      Пользователем сайта и Администрацией сайта, обязательным является
      предъявление претензии (письменного предложения о добровольном
      урегулировании спора).
    </p>
    <p>
      7.2 Получатель претензии в течение 30 календарных дней со дня получения
      претензии, письменно уведомляет заявителя претензии о результатах
      рассмотрения претензии.
    </p>
    <p>
      7.3. При не достижении соглашения спор будет передан на рассмотрение в
      судебный орган в соответствии с действующим законодательством Украины.
    </p>
    <p>
      7.4. К этой Политики конфиденциальности и отношений между Пользователем и
      Администрацией сайта применяется действующее законодательство Украины.
    </p>
    <br />
    <p>8. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</p>
    <br />
    <p>
      8.1. Администрация сайта имеет право вносить изменения в настоящую
      Политику конфиденциальности без согласия пользователя.
    </p>
    <p>
      8.2. Новая Политика конфиденциальности вступает в силу с момента ее
      размещения на Сайте, если иное не предусмотрено новой редакцией Политики
      конфиденциальности.
    </p>
    <p>
      8.3. Все предложения или вопросы по этой Политике конфиденциальности
      следует отправлять на почту расположенную в разделе контакты.
    </p>
    <p>
      8.4. Действующая Политика конфиденциальности размещена на странице по
      адресу <a href="mailto:webinars.glau.com.ua">webinars.glau.com.ua</a>
    </p>
  </UserTexts>
);

export default UserAgreementsTextRU;
