import Loader from '@/components/Loader';
import { useAppDispatch } from '@/utils/hooks';
import { setUserInstance, useGetProfileQuery } from '@redux/auth';
import React, { useEffect } from 'react';

interface IAuthLayoutProps {
  children: JSX.Element;
}
export default function AuthLayoutBlock({
  children,
}: IAuthLayoutProps): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    data: user,
    isLoading,
    isSuccess,
  } = useGetProfileQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    if (user && isSuccess) {
      dispatch(setUserInstance(user));
    }
  }, [user]);

  if (isLoading) return <Loader />;
  // return <Loader />;
  return children;
}
