import { RootState } from '@/redux';
import { createSelector } from '@reduxjs/toolkit';

export const selectUsers = (state: RootState): typeof state.users =>
  state.users;

export const selectCurrentModalUserState = createSelector(
  selectUsers,
  (state) => state.usersModal
);
