import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

const StyledLoader = styled(ClipLoader)``;

export const StyledLoaderContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default StyledLoader;
