import React from 'react';
import { UserTexts } from '../UserAgreements/styled';

const PaymentDelUA = (): JSX.Element => (
  <UserTexts>
    <h2>Умови оплати та доступу до вебінарів</h2>
    <br />
    <p>Оплата</p>
    <br />
    <p>1. Безготівковий розрахунок карткою онлайн через ситему LiqPay</p>
    <p>
      2. Безготівковий розрахунок по реквізитам, в разі сплати від юридичної
      особи.
    </p>
    <br />
    <p>Доступ до вебінарів:</p>
    <br />
    <p>
      1. При оплаті карткою доступ до вебінарів надається після успішної оплати
      строком на 30 днів.{' '}
    </p>
    <p>
      2. При оплаті безготівковим розрахунком доступ надається після
      підтвердження бухгалтерією вашої оплати, строком на 30 днів.
    </p>
    <p>
      3. Після закінчення строку доступу, Ви можете продовжити його за пів
      вартості вебінару строком на 30 днів.
    </p>
  </UserTexts>
);

export default PaymentDelUA;
