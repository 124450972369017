import { UserStatusType } from '@/interfaces/IUser';
import withAuthType from '@/layouts/Auth/AuthBasedLayout';
import {
  BurgerMenuButton,
  FlexRow,
  MainHeader,
  MainHeaderItem,
  MainHeaderLogo,
  MainHeaderUserInfo,
  StyledMaxWidthWrapper,
} from './styled';
import Loader from '@components/Loader';
import { useGetProfileQuery } from '@redux/auth';
import { changeSidebarState } from '@redux/css';
import { useAppDispatch, useToggle } from '@utils/hooks';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as BurgerMenuIcon } from '../../assets/icons/burger-menu.svg';
import { ReactComponent as NotificationsIcon } from '../../assets/icons/notifications.svg';
import Logo from '../../assets/img/logo.png';
import ProfilePic from '../../assets/img/user-photo.png';
import { useChangePreferLanguageMutation } from '@/redux/users';

const Header = (): JSX.Element => {
  const { data: user, isLoading: isUserLoading } = useGetProfileQuery();
  const [changeLanguage] = useChangePreferLanguageMutation();

  const { i18n } = useTranslation();

  const [state, toggle] = useToggle(
    user?.preferredLanguage === 'ru' ? true : false
  );

  const dispatch = useAppDispatch();

  const toggleLanguage = async function () {
    toggle();
    await changeLanguage().unwrap();
  };

  const language = useMemo(() => {
    return state ? 'ru' : 'ua';
  }, [state]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const sidebarChangeState = () => {
    dispatch(changeSidebarState());
  };

  if (isUserLoading) return <Loader />;

  if (!user) return <div>Missing user!</div>;

  return (
    <MainHeader>
      <StyledMaxWidthWrapper>
        <Link to="/">
          <MainHeaderLogo src={Logo} alt="" />
        </Link>
        <FlexRow>
          <Link to="/notifications">
            <MainHeaderItem>
              <NotificationsIcon />
            </MainHeaderItem>
          </Link>
          <MainHeaderItem onClick={toggleLanguage}>
            <p>{language.toUpperCase()}</p>
          </MainHeaderItem>
          <MainHeaderUserInfo>
            {user.name && (
              <>
                <img src={ProfilePic} alt="" />
                <p>{user.name}</p>
              </>
            )}
          </MainHeaderUserInfo>
          <BurgerMenuButton onClick={sidebarChangeState}>
            <BurgerMenuIcon />
          </BurgerMenuButton>
        </FlexRow>{' '}
      </StyledMaxWidthWrapper>
    </MainHeader>
  );
};

export default withAuthType([UserStatusType.AUTHED, UserStatusType.ADMIN])(
  Header
);
